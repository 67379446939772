import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { ModalError } from "components";
import { useUIContext } from "pages/ui-context";
import DailyQuota from "./components/DailyQuota";
import FormSwap from "./components/form-swap";
import "./styles.scss";
import withHeader from "hocs/withHeader";

const EvryHub = (props) => {
	const { wallet, walletWarning, setWalletWarning, setWallet } =
		useUIContext();

	const handleCloseWarningModal = useCallback(() => {
		setWallet(null);
		setWalletWarning(false);
	}, []);

	const renderFormSwap = useMemo(() => <FormSwap />, []);

	return (
		<div className="new-home">
			<div className="layout">
				<DailyQuota />

				<div className="main-section">
					<div className="home-descrip">
						The safe, fast and most secure way to bring cross-chain
						assets to Nova chains.
					</div>
					<div className="left">
						<div className="left-main">
							<div className="left-main-link">
								<Link to="/documents/user_guide">
									User Guide
								</Link>
							</div>
						</div>
					</div>
					<div>
						<div>
							<div className={"_mgbt-6 _pdh-6 _pdh-0:sm"}>
								<div className={"_fs-10 _fw-600"}>Warp</div>
								<p className={"_fs-4 _mgt-3"}>
									The safe, fast and most secure way to bring
									cross-chain asset
								</p>
							</div>
							<div className={"swap-content"}>
								<div className="swap-content__layout nm-card">
									{renderFormSwap}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ModalError
				wallet={wallet}
				visible={walletWarning}
				onCancel={handleCloseWarningModal}
				destroyOnClose
			/>
		</div>
	);
};

export default withHeader(EvryHub);
