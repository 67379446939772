import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Button, Form, Input } from "antd";
import Web3 from "web3";
import { Image, ModalConfirm, ModalError, ModalRejected } from "components";
import { WALLET } from "constants/walletExtension";
import { MULTICHAIN_NETWORK_SWAP } from "constants/multichain";
import { convertRound } from "hook/ultis.hook";
import {
	checkTokenAllow,
	getUserApprove,
	getSwapNetworkType,
	isNativeToken,
	ToAbsoluteUrl,
	truncateAddressMid,
	userSignTransactionStellar,
} from "ultis";
import { useUIContext } from "pages/ui-context";
import StellarBase from "stellar-base";
import featureApi from "api/featureApi";
import stellar_api from "api/stellar_api";
import BigNumber from "bignumber.js";
import { zeroCutter } from "ultis";

const FormConnected = ({
	fromNetwork,
	toNetwork,
	assetChoosen,
	tokenBalance,
	isConnectedBadgeAtFrom,
	isConnectedBadgeAtTo,
	checkIsCurrentNetwork,
}) => {
	const [form] = Form.useForm();
	const { address, network, wallet, web3Instance } = useUIContext();
	const [approveReject, setApproveReject] = useState(false);
	const [isApproved, setIsApproved] = useState(false);
	const [confirmTransaction, setConfirmTransaction] = useState(false);
	const [isTxSending, setIsTxSending] = useState(0);
	const [changeDes, setChangeDes] = useState(false);
	const [isFreighterInstalled, setIsFreighterInstalled] = useState(true);
	const [trustLineError, setTrustLineError] = useState({
		visible: false,
		message: "",
	});

	const validateTokenDecimal = (value) => {
		if (value?.toString()?.indexOf(".") !== -1) {
			let decimalLength =
				value.toString()?.length -
				value
					.toString()
					?.substring(0, value.toString()?.indexOf(".") + 1).length;
			if (assetChoosen && isNativeToken(assetChoosen.address)) {
				return decimalLength - "18" > 0;
			}

			return decimalLength - assetChoosen.decimals.from > 0;
		}
		return false;
	};

	const onCancelTrustLineError = () => {
		setTrustLineError({ ...trustLineError, visible: false });
	};

	const handleOpenConfirm = useCallback(() => {
		setIsTxSending(0);
		setIsApproved(true);
		setConfirmTransaction(true);
	}, []);

	const handleTrustLineError = useCallback((message) => {
		setIsTxSending(0);
		setTrustLineError({ visible: true, message });
		setIsApproved(false);
	}, []);

	const checkIsFreighterInstall = useCallback(async () => {
		try {
			const isFreighterInstall = await WALLET.freighter.isInstalled();
			if (isFreighterInstall) {
				setIsFreighterInstalled(true);
				return true;
			}
			setIsTxSending(0);
			setIsFreighterInstalled(false);
			setIsApproved(false);
			return false;
		} catch (error) {
			setIsTxSending(0);
			setIsFreighterInstalled(false);
			setIsApproved(false);
			return false;
		}
	}, []);

	const checkIsStellarAccountAvail = useCallback(async (account) => {
		try {
			const stellarAccount = await WALLET.freighter.request.connect();
			if (stellarAccount !== account) {
				handleTrustLineError(
					"Your destination account is not connected via freighter wallet"
				);
				return false;
			}
			return true;
		} catch (error) {
			handleTrustLineError(
				"Can not validate your destination account or something has happened"
			);
			return false;
		}
	}, []);

	const signTrustLineStellar = async () => {
		try {
			setIsTxSending(2);
			const trustLinePayload = {
				token: assetChoosen.address,
				blockchainName: fromNetwork.networkSignature,
				chainName: toNetwork.networkSignature,
				sender: address,
				recipient: form.getFieldValue("recipientAddress"),
			};
			const trustLineRes = await featureApi.checkStellarTrustLine(
				trustLinePayload
			);
			if (trustLineRes.success && trustLineRes.xdr) {
				const isFreighterInstall = await checkIsFreighterInstall();
				if (!isFreighterInstall) return;
				const isAccountAvailable = await checkIsStellarAccountAvail(
					form.getFieldValue("recipientAddress")
				);
				if (!isAccountAvailable) return;

				// sign stellar
				const [userSign, signError] = await userSignTransactionStellar(
					trustLineRes.xdr,
					toNetwork.key
				);
				if (signError) {
					handleTrustLineError(
						"Something has happened or User failed to sign transaction "
					);
					return;
				}
				await stellar_api.submitTransactionStellar(userSign);
				handleOpenConfirm();
				return;
			}
			if (trustLineRes.code.includes("USER_HAS_TRUST_LINE")) {
				handleOpenConfirm();
				return;
			}
			//modal error
			handleTrustLineError(
				`Something has happened, fail to get trust line on ${toNetwork.name}`
			);
			return;
		} catch (error) {
			// modal error
			handleTrustLineError(
				"Something has happened or User failed to sign transaction "
			);
			return;
		}
	};

	const checkAllow = async () => {
		try {
			const { bridgeTokenABI, bridgeBankAddress } =
				MULTICHAIN_NETWORK_SWAP[
				getSwapNetworkType(fromNetwork.networkSignature)
				];
			const payload = {
				web3Instance,
				walletAddress: address,
				bridgeToken_ABI: bridgeTokenABI,
				bridgeBankAddress,
				assetChoosen,
				tokenBalance,
			};
			const allowanceCheck = await checkTokenAllow(
				wallet?.chain,
				payload
			);
			return allowanceCheck;
		} catch (error) {
			return false;
		}
	};

	const handleSwapToken = async (values) => {
		if (wallet?.chain === "stellar") {
			handleOpenConfirm();
			return;
		}
		const isTokenNeedTrustLine =
			toNetwork.chain === "stellar" &&
			assetChoosen.name !== toNetwork.nativeCurrency.symbol;

		if (isApproved || isNativeToken(assetChoosen.address)) {
			// sign trust line first
			if (isTokenNeedTrustLine) {
				signTrustLineStellar();
				return;
			}
			// if is approve and no need trustline
			handleOpenConfirm();
			return;
		}

		const { bridgeTokenABI, bridgeBankAddress } =
			MULTICHAIN_NETWORK_SWAP[
			getSwapNetworkType(fromNetwork.networkSignature)
			];
		const allowed = await checkAllow();
		if (allowed) {
			// sign trust line first
			if (isTokenNeedTrustLine) {
				signTrustLineStellar();
				return;
			}
			handleOpenConfirm();
			return;
		}
		// if not allow yet, user need to approve token
		setIsTxSending(1);
		const payload = {
			web3Instance,
			walletAddress: address,
			bridgeToken_ABI: bridgeTokenABI,
			bridgeBankAddress,
			tokenAddress: assetChoosen.address,
			wallet,
		};
		const approve = await getUserApprove(wallet?.chain, payload);
		// console.log(approve);
		setIsTxSending(0);
		if (approve) {
			setIsApproved(true);
			return;
		}
		form.resetFields();
		setApproveReject(true);
		setIsApproved(false);
	};

	const handleClickMax = (fieldName, setFieldsValue) => {
		const ExtraFee = "0.01";
		const checkIfNativeCoin =
			assetChoosen?.name === network?.nativeCurrency.symbol;
		const fee = checkIfNativeCoin ? ExtraFee : 0;
		const tokenBalanceToBN = new BigNumber(tokenBalance);
		const res = tokenBalanceToBN.minus(fee).toString();
		setFieldsValue({
			[fieldName]: zeroCutter(res),
		});
		form.validateFields();
	};

	const handleChangeDes = () => {
		setChangeDes(true);
	};

	const handleCancleChangeDes = () => {
		setChangeDes(false);
		form.resetFields(["recipientAddress"]);
	};

	const onCancelApproveReject = () => {
		setApproveReject(false);
	};

	// const isWalletAddress = async (vAddress) => {
	//   const RPC_ETH = process.env.REACT_APP_ETH_PROVIDER;
	//   const RPC_BSC = process.env.REACT_APP_BSC_PROVIDER;

	//   const providerETH = new Web3.providers.HttpProvider(RPC_ETH);
	//   const providerBSC = new Web3.providers.HttpProvider(RPC_BSC);

	//   const web3ETH = new Web3(providerETH);
	//   const web3BSC = new Web3(providerBSC);

	//   const result = await Promise.all([
	//     await web3ETH.eth.getCode(vAddress),
	//     await web3BSC.eth.getCode(vAddress),
	//   ]);

	//   return result[0] === '0x' && result[1] === '0x';
	// };

	const handleConfirm = useCallback(() => {
		form.resetFields();
		setIsTxSending(1);
	}, [form]);
	const handleSuccess = useCallback(() => {
		setIsTxSending(0);
	}, [form]);

	const handleError = useCallback(() => {
		setIsTxSending(0);
	}, []);

	const renderBalance = useMemo(() => {
		const decimals =
			assetChoosen?.decimals?.from > 8 || assetChoosen?.decimals?.from < 0
				? 8
				: assetChoosen?.decimals?.from;
		return (
			<div className="select-token-group__available">
				Available: {convertRound(tokenBalance, decimals)}{" "}
				{assetChoosen.name}
			</div>
		);
	}, [tokenBalance, assetChoosen]);

	const btnNextLoadingStatus = useMemo(() => {
		switch (isTxSending) {
			case 1:
				if (isApproved) return "Sending";
				return "Approving";
			case 2:
				return "Checking";
		}
	}, [isApproved, isTxSending]);

	const isSelfDes = useMemo(
		() =>
			!changeDes &&
			wallet?.chain !== "stellar" &&
			toNetwork.networkSignature !== "stellar",
		[changeDes, wallet, toNetwork]
	);

	useEffect(() => {
		setChangeDes(false);
		form.resetFields(["recipientAddress"]);
	}, [fromNetwork, toNetwork]);

	// useEffect(() => {
	//   form.validateFields();
	// }, [fromNetwork, toNetwork, changeDes]);

	useEffect(() => {
		form.resetFields(["amount"]);
	}, [assetChoosen.address, assetChoosen.name, toNetwork]);

	useEffect(() => {
		form.resetFields();
	}, [
		network,
		isConnectedBadgeAtTo,
		isConnectedBadgeAtFrom,
		wallet,
		address,
	]);

	useEffect(() => {
		async function getAllow() {
			if (wallet?.chain === "stellar") {
				setIsApproved(true);
				return;
			}
			if (
				assetChoosen &&
				tokenBalance &&
				assetChoosen.address &&
				!isNativeToken(assetChoosen.address)
			) {
				const allowed = await checkAllow();
				setIsApproved(allowed);
			}
		}
		if (isConnectedBadgeAtFrom) getAllow();
	}, [tokenBalance, assetChoosen.address, isConnectedBadgeAtFrom]);

	return (
		<>
			<Form form={form} layout="vertical" onFinish={handleSwapToken}>
				{!isConnectedBadgeAtFrom || isConnectedBadgeAtTo ? (
					<div className="select-token-group__alert">
						<Image
							className="alerticon"
							src={ToAbsoluteUrl("/images/icon/icon-alert.svg")}
							alt=""
						/>
						<div className="alerttext">
							{`To protect your asset, from network must be current network of
              your wallet. Switch to your ${network?.networkName} or change the
              direction of your swap networks.`}
						</div>
					</div>
				) : (
					<>
						<div className="select-token-group">
							<div className="select-token-group__text _mgbt-5">
								Destination
							</div>
							{isSelfDes ? (
								<div
									className="select-token-group__select-field"
									onClick={handleChangeDes}
								>
									<div className="field">
										<img
											src={ToAbsoluteUrl(wallet?.icon)}
											alt=""
											className="image-icon field-icon"
										/>
										<span className="pl-xsm des-text">
											{truncateAddressMid(address, 8, 8)}
										</span>
									</div>
								</div>
							) : (
								<div className="amount-input-container">
									<Form.Item
										label={``}
										name="recipientAddress"
										rules={[
											() => ({
												color: "red",
												required: true,
												validator: async (
													rule,
													value
												) => {
													if (!value) {
														return Promise.reject(
															"Recipient’s Address is required!"
														);
													}
													if (value.length > 255) {
														return Promise.reject(
															`max length allowed is 255`
														);
													}
													if (
														toNetwork.networkSignature ===
														"stellar" &&
														!StellarBase.StrKey.isValidEd25519PublicKey(
															value.trim()
														)
													) {
														return Promise.reject(
															`Recipient’s Address must be a valid public key.`
														);
													}
													if (
														toNetwork.networkSignature !==
														"stellar" &&
														!Web3.utils.isAddress(
															value.trim()
														)
													) {
														return Promise.reject(
															`Recipient’s Address is invalid.`
														);
													}

													return Promise.resolve();
												},
											}),
										]}
									>
										<Input
											className="select-token-group__amount u-input-style"
											maxLength="255"
											autoComplete="off"
										/>
									</Form.Item>
									{wallet?.chain !== "stellar" &&
										toNetwork.networkSignature !== "stellar" ? (
										<div
											onClick={handleCancleChangeDes}
											className="select-token-group__amount__max-btn rounded-md text-md"
										>
											Cancel
										</div>
									) : null}
								</div>
							)}
						</div>
						<div
							className="select-token-group__available"
							style={{ color: "#BDBDBD" }}
						>
							This is the arrival network address
						</div>
					</>
				)}

				<div className="select-token-group">
					<div className="select-token-group__text _mgbt-5">
						Amount
					</div>
					<div className="amount-input-container">
						<Form.Item
							label={``}
							name="amount"
							// validateTrigger='onBlur'
							required={true}
							rules={[
								() => ({
									color: "red",
									required: true,
									validator: async (rule, value) => {
										const valueToBN = new BigNumber(value);
										const tokenBalanceToBN = new BigNumber(
											tokenBalance
										);
										if (
											!isConnectedBadgeAtFrom ||
											isConnectedBadgeAtTo
										) {
											return Promise.resolve();
										}
										if (!value) {
											return Promise.reject(
												"Amount is required!"
											);
										}
										if (
											valueToBN.isLessThan(
												assetChoosen.min
											)
										) {
											return Promise.reject(
												`Amount min is ${assetChoosen.min}.`
											);
										}
										if (
											valueToBN.isGreaterThan(
												assetChoosen.max
											)
										) {
											return Promise.reject(
												`Amount max is ${assetChoosen.max}.`
											);
										}
										if (
											valueToBN.isGreaterThan(
												tokenBalanceToBN
											)
										) {
											return Promise.reject(
												`Insufficient ${assetChoosen.name} balance.`
											);
										}

										const validateDecimal =
											await validateTokenDecimal(value);
										if (validateDecimal) {
											return Promise.reject(
												`The maximum of amount decimals allowed are: ${assetChoosen.decimals.from}`
											);
										}
										return Promise.resolve();
									},
								}),
							]}
						>
							<Input
								className="select-token-group__amount u-input-style"
								type="number"
								inputMode="decimal"
								min="0"
								step="any"
								onKeyDown={(evt) => {
									return (
										["e", "E", "+", "-"].includes(
											evt.key
										) && evt.preventDefault()
									);
								}}
								onKeyPress={(evt) => {
									return (
										["e", "E", "+", "-"].includes(
											evt.key
										) && evt.preventDefault()
									);
								}}
								onPaste={(evt) => {
									return (
										(evt.clipboardData
											.getData("text")
											?.indexOf("-") !== -1 ||
											evt.clipboardData
												.getData("text")
												?.indexOf("+") !== -1 ||
											evt.clipboardData
												.getData("text")
												?.indexOf("e") !== -1 ||
											evt.clipboardData
												.getData("text")
												?.indexOf("E") !== -1 ||
											isNaN(
												Number(
													evt.clipboardData.getData(
														"text"
													)
												)
											)) &&
										evt.preventDefault()
									);
								}}
								onWheel={(e) => e.target.blur()}
								autoComplete="off"
							/>
						</Form.Item>
						{tokenBalance &&
							tokenBalance > 0.01 &&
							checkIsCurrentNetwork(fromNetwork) ? (
							<div
								onClick={() => {
									handleClickMax(
										"amount",
										form.setFieldsValue
									);
								}}
								className="select-token-group__amount__max-btn rounded-md text-md"
							>
								Max
							</div>
						) : (
							<div className="select-token-group__amount__max-btn disable rounded-md text-md">
								Max
							</div>
						)}
					</div>
				</div>

				{!isConnectedBadgeAtTo && isConnectedBadgeAtFrom ? (
					<>
						{renderBalance}
						<div className="select-token-group__alert">
							<Image
								className="alerticon"
								src={ToAbsoluteUrl(
									"/images/icon/icon-alert.svg"
								)}
								alt=""
							/>
							<div className="alerttext">
								Your swap address will be your receiving
								address, <br />
								please switch the network to check your balance
								after completion.
							</div>
						</div>
					</>
				) : null}

				<div className="swap-button">
					<Form.Item shouldUpdate>
						{() => (
							<Button
								className={`swap-button__btn ${isTxSending ? "loading" : ""
									}`}
								disabled={
									isTxSending ||
									assetChoosen.name.length < 1 ||
									!(isSelfDes
										? form.isFieldTouched("amount")
										: form.isFieldsTouched(true)) ||
									form
										.getFieldsError()
										.filter(({ errors }) => {
											return errors.length;
										}).length > 0 ||
									isConnectedBadgeAtTo ||
									!isConnectedBadgeAtFrom
								}
								htmlType="submit"
							>
								{isTxSending ? (
									<>
										<span className="mr-md">
											{btnNextLoadingStatus}
										</span>
										<span className="loading__icon">
											<img
												src={ToAbsoluteUrl(
													"/images/icon/icon-loading.svg"
												)}
											/>
										</span>
									</>
								) : form.isFieldsTouched(true) &&
									isConnectedBadgeAtFrom ? (
									!isNativeToken(assetChoosen.address) &&
										isApproved ? (
										"Next"
									) : (
										"Approve"
									)
								) : (
									"Next"
								)}
							</Button>
						)}
					</Form.Item>
				</div>

				<div className="select-token-group__reminder">
					<div>
						<img
							src={ToAbsoluteUrl(
								"/images/icon/icon-reminder.svg"
							)}
							alt=""
							className="reminder-icon"
						/>
						Reminder
					</div>
					<div className="reminder-text">
						1. Minimum amount is {assetChoosen.min}{" "}
						{assetChoosen.name}
					</div>
					<div className="reminder-text">
						2. Maximum amount is {assetChoosen.max}{" "}
						{assetChoosen.name}
					</div>
				</div>
			</Form>
			<ModalError
				visible={!isFreighterInstalled}
				wallet={WALLET.freighter}
				onCancel={() => setIsFreighterInstalled(true)}
			/>
			<ModalRejected
				title="Trust line error"
				visible={trustLineError.visible}
				message={trustLineError.message}
				onCancel={onCancelTrustLineError}
			/>
			<ModalRejected
				title="Error"
				visible={approveReject}
				onCancel={onCancelApproveReject}
			/>
			{assetChoosen?.name ? (
				<ModalConfirm
					wallet={wallet}
					web3Instance={web3Instance}
					visible={confirmTransaction}
					pushUpAgain={() => setConfirmTransaction(true)}
					contractInfor={
						MULTICHAIN_NETWORK_SWAP[
						getSwapNetworkType(fromNetwork.networkSignature)
						]
					}
					transactionInfor={{
						address,
						destinationAddress:
							!isSelfDes &&
								form.getFieldValue("recipientAddress")?.length > 0
								? form.getFieldValue("recipientAddress")
								: address,
						assetChoosen,
						fromNetwork,
						toNetwork,
						amount: form.getFieldValue("amount"),
						tokenAddress: assetChoosen.address,
					}}
					onCancel={() => {
						setConfirmTransaction(false);
						// form.validateFields();
						// setChangeDes(false)
					}}
					onConfirm={handleConfirm}
					onSuccess={handleSuccess}
					onError={handleError}
				/>
			) : null}
		</>
	);
};

export default React.memo(FormConnected);
