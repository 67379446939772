import { DECIMALS_CACHES } from "constants/index";
import { MULTICHAIN_SUPPORT_ID } from "constants/multichain";
import React, { useEffect, useMemo, useState } from "react";
import { isMobile, isTablet } from "react-device-detect";
import {
	formWei,
	getSingleLineFromCache,
	getTokenDecimalEthereum,
	roundNumber,
} from "ultis";
import "./index.scss";

const AmountLabel = ({ data, className }) => {
	const { amount, tokenAddress, network, symbol } = data;
	const [decimals, setDecimals] = useState(null);
	const origin = window.location.origin;

	const getTokenDecimal = async () => {
		try {
			const key = `${symbol}/${network}`;
			if (network === "stellar") {
				setDecimals(0);
				return;
			}
			const networkMatch = MULTICHAIN_SUPPORT_ID[network];
			const [cachingDecimal, cacheError] = await getSingleLineFromCache(
				DECIMALS_CACHES,
				origin,
				key
			);
			if (
				cachingDecimal &&
				networkMatch.decimal === cachingDecimal.chain_id
			) {
				setDecimals(parseInt(cachingDecimal.decimals));
				return;
			}
			const [res, error] = await getTokenDecimalEthereum({
				chain: network,
				token: { address: tokenAddress, symbol },
			});
			if (res) {
				setDecimals(res.tokenDecimal);
				return;
			}
		} catch (error) {
			console.log(error);
		}
	};

	const renderAmount = useMemo(() => {
		if (decimals === null || !amount) return "0";
		if (network === "stellar") return roundNumber(amount, 8);
		const decimalsResponsive = isMobile || isTablet ? 6 : 8;
		return roundNumber(
			formWei(amount, decimals),
			decimals > decimalsResponsive ? decimalsResponsive : decimals
		);
	}, [amount, decimals, network]);

	useEffect(() => {
		getTokenDecimal();
	}, []);

	return <span className={`span1 ${className}`}>{renderAmount}</span>;
};
export default AmountLabel;
