import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import Modal from "antd/lib/modal/Modal";
import { ToAbsoluteUrl } from "ultis";
import "./style.scss";

ModalError.propTypes = {
	visible: PropTypes.bool,
	title: PropTypes.string,
	onCancel: PropTypes.func,
};

ModalError.defaultProps = {
	visible: false,
	title: "Ooops!",
};

function ModalError({ visible, title, onCancel, wallet, ...props }) {
	return (
		<Modal
			{...props}
			visible={visible}
			title={title}
			centered
			onCancel={onCancel}
			className="modal modal-error"
			closeIcon={
				<img src={ToAbsoluteUrl("/images/icon/icon-close-btn.svg")} />
			}
			footer={
				props.btnMessage ? (
					<Button
						onClick={onCancel}
						block
						className="modal-error-dismiss__close-btn"
					>
						{props.btnMessage}
					</Button>
				) : (
					<a
						key="submit"
						className="nm-button is-fluid"
						href={wallet?.installation_link}
						target="_blank"
						rel="noreferrer"
					>
						Install extension
					</a>
				)
			}
		>
			<img
				src={ToAbsoluteUrl(
					props.img ? props.img : "/images/icon/icon-warning.svg"
				)}
				alt=""
			/>
			<h6 style={{ width: "100%", textAlign: "center" }}>
				{props.errorDescription
					? props.errorDescription
					: `Looks like you don't have the ${wallet?.name} browser extension installed
        yet. Head over to the Chrome/Firefox Extension to quickly install the
        extension.`}
			</h6>
		</Modal>
	);
}

export default ModalError;
