export { default as Bridge_ETH } from "./ABI/Bridge_ETH.json";
export { default as Bridge_BSC } from "./ABI/Bridge_BSC.json";
export { default as Bridge_EVRY } from "./ABI/Bridge_EVRY.json";
export { default as BridgeToken_ETH } from "./ABI/BridgeToken_ETH.json";
export { default as BridgeToken_BSC } from "./ABI/BridgeToken_BSC.json";
export { default as BridgeToken_EVRY } from "./ABI/BridgeToken_EVRY.json";

const ETH_PROVIDER = process.env.REACT_APP_ETH_PROVIDER;
const BSC_PROVIDER = process.env.REACT_APP_BSC_PROVIDER;
const EVRYNET_PROVIDER = process.env.REACT_APP_EVRYNET_PROVIDER;

const CONTRACT_ADDRESS = {
	bridgeETH: `${process.env.REACT_APP_ETH_BRIDGE_ADDRESS}`,
	bridgeBSC: `${process.env.REACT_APP_BSC_BRIDGE_ADDRESS}`,
	bridgeEVRYNET: `${process.env.REACT_APP_EVRYNET_BRIDGE_ADDRESS}`,
	bridgeStellar: `${process.env.REACT_APP_STELLAR_BRIDGE_ADDRESS}`,
};

const DECIMALS_CACHES = "decimalsCache";

const STELLAR_DECIMAL = 7;

const ASSET_CONFIG = {
	eth: {
		type: "ERC20",
		convertSymbol: "ether",
	},
	bsc: {
		type: "ERC20",
		convertSymbol: "ether",
	},
};

const DEFAULT_TOKEN_ICON = "/images/icon/icon-circle_grey.png";

const MaxUint =
	"115792089237316195423570985008687907853269984665640564039457584007913129639935";

const NETWORK_ID = {
	ropstenTestNet: {
		hex: "0x3",
		decimal: "3",
		name: "Ropsten Test Network",
	},
	rinkebyTestNet: {
		hex: "0x4",
		decimal: "4",
		name: "Rinkeby Test Network",
		bscChainSwitch: "eth-mainnet",
	},
	goerliTestNet: {
		hex: "0x5",
		decimal: "5",
		name: "	Goerli Test Network",
		bscChainSwitch: "eth-mainnet",
	},
	kovanTestNet: {
		hex: "0x2a",
		decimal: "42",
		name: "Kovan Test Network",
	},
	EthereumMainNet: {
		hex: "0x1",
		decimal: "1",
		name: "Ethereum mainnet",
		bscChainSwitch: "eth-mainnet",
	},
	BSCTestNet: {
		hex: "0x61",
		decimal: "97",
		name: "BSC Test Network",
		bscChainSwitch: "bsc-testnet",
		multicallContractAddress: "0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576",
	},
	BSCMainNet: {
		hex: "0x38",
		decimal: "56",
		name: "BSC Main Network",
		bscChainSwitch: "bsc-mainnet",
		multicallContractAddress: "0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B",
	},
	EvryTestNet: {
		hex: "0x2d",
		decimal: "45",
		name: "Evrynet Test Network",
	},
	EvryMainNet: {
		hex: "0xF",
		decimal: "15",
		name: "Evrynet Main Network",
	},
	StellarTestNet: {
		hex: "0x0",
		key: "TESTNET",
		name: "Stellar Test NetWork",
	},
	StellarPublicNet: {
		key: "PUBLIC",
		name: "Stellar Public Network",
	},
};

const CONVERT_NETWORK_TYPE = {
	from_eth: "1",
	from_bsc: "2",
	from_stellar: "3",
	from_evry: "4",
};

const NETWORK_TYPE = process.env.REACT_APP_NETWORK;

const DECIMALS = [
	{ key: "1", value: "wei" },
	{ key: "3", value: "kwei" },
	{ key: "6", value: "mwei" },
	{ key: "9", value: "gwei" },
	{ key: "12", value: "micro" },
	{ key: "15", value: "milliether" },
	{ key: "18", value: "ether" },
	{ key: "21", value: "kether" },
	{ key: "24", value: "mether" },
	{ key: "27", value: "gether" },
	{ key: "30", value: "tether" },
];

const BSC_PROVIDERS_TESTNET = [
	"https://data-seed-prebsc-1-s1.binance.org:8545",
	"https://data-seed-prebsc-2-s1.binance.org:8545",
	"https://data-seed-prebsc-1-s2.binance.org:8545",
	"https://data-seed-prebsc-2-s2.binance.org:8545",
	"https://data-seed-prebsc-1-s3.binance.org:8545",
	"https://data-seed-prebsc-2-s3.binance.org:8545",
];

const BSC_PROVIDERS_MAINNET = [
	"https://bsc-dataseed.binance.org/",
	"https://bsc-dataseed1.defibit.io/",
	"https://bsc-dataseed1.ninicoin.io/",
	"https://bsc-dataseed2.defibit.io/",
	"https://bsc-dataseed3.defibit.io/",
	"https://bsc-dataseed4.defibit.io/",
	"https://bsc-dataseed2.ninicoin.io/",
	"https://bsc-dataseed3.ninicoin.io/",
	"https://bsc-dataseed4.ninicoin.io/",
	"https://bsc-dataseed1.binance.org/",
	"https://bsc-dataseed2.binance.org/",
	"https://bsc-dataseed3.binance.org/",
	"https://bsc-dataseed4.binance.org/",
];
const BSC_PROVIDERS =
	process.env.REACT_APP_REQUIRE_BSC_NETWORK === "BSCTestNet"
		? BSC_PROVIDERS_TESTNET
		: BSC_PROVIDERS_MAINNET;

export {
	CONTRACT_ADDRESS,
	STELLAR_DECIMAL,
	MaxUint,
	NETWORK_ID,
	ASSET_CONFIG,
	DECIMALS_CACHES,
	NETWORK_TYPE,
	DECIMALS,
	CONVERT_NETWORK_TYPE,
	DEFAULT_TOKEN_ICON,
	ETH_PROVIDER,
	BSC_PROVIDER,
	BSC_PROVIDERS,
	EVRYNET_PROVIDER,
};
