import StellarSdk from "stellar-sdk";

const STELLAR_SERVER_URL = process.env.REACT_APP_STELLAR_PROVIDER;
const server = new StellarSdk.Server(STELLAR_SERVER_URL);

export default {
	getAccount(account) {
		return server.loadAccount(account);
	},

	async submitTransactionStellar(userSignedTransaction) {
		try {
			const transactionToSubmit =
				await StellarSdk.TransactionBuilder.fromXDR(
					userSignedTransaction,
					STELLAR_SERVER_URL
				);
			return await server.submitTransaction(transactionToSubmit);
		} catch (err) {
			return err.response.data.extras;
		}
	},
};
