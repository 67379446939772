import React, { useEffect, useState, useCallback } from "react";
import featureApi from "api/featureApi";
import { convertAddress } from "ultis";
import { useUIContext } from "pages/ui-context";

function DailyQuota() {
	const { address, userIsConnected } = useUIContext();
	const [dailyQuota, setDailyQuota] = useState("0.00");
	const [maxQuota, setMaxQuota] = useState("0.00");
	// const [stop, setStop] = useState(false);
	const [refreshing, setRefreshing] = useState(false);

	const resetDailyQuota = useCallback(() => {
		setDailyQuota("0.00");
	}, []);
	const resetMaxQuota = useCallback(() => {
		setMaxQuota("0.00");
	}, []);
	const getAddressQuota = async () => {
		try {
			setRefreshing(false);
			const addressConverted = convertAddress(address);
			const quotaDaily = await featureApi.getDailyQuota({
				address: addressConverted,
			});
			setDailyQuota(quotaDaily.quota);
			setRefreshing(true);
		} catch (error) {
			resetDailyQuota();
			setRefreshing(true);
		}
	};

	const getMaxQuotal = useCallback(async () => {
		try {
			const quotaMax = await featureApi.getQuota();
			if (quotaMax[0].amount != maxQuota) setMaxQuota(quotaMax[0].amount);
		} catch (error) {
			resetMaxQuota();
		}
	}, []);

	useEffect(() => {
		if (userIsConnected) {
			getMaxQuotal();
			getAddressQuota();
			return;
		}
		setRefreshing(false);
		resetDailyQuota();
		resetMaxQuota();
	}, [userIsConnected]);

	useEffect(() => {
		let mount = true;
		if (!refreshing) return;
		const clear = setTimeout(() => {
			if (mount) getAddressQuota();
		}, 5000);
		return () => {
			mount = false;
			clearTimeout(clear);
		};
	}, [refreshing]);

	return userIsConnected ? (
		<div className="daily-quota _tal-ct _dp-f _jtfct-ct">
			<div
				className={"_pdv-5 _pdh-6 _bdrd-3 _bgcl-white _bgcl-opacity-10"}
			>
				{`Daily quota ${maxQuota} USD per address \n(${dailyQuota} USD / ${maxQuota} USD)`}
			</div>
		</div>
	) : null;
}

export default React.memo(DailyQuota);
