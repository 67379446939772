import React, { useEffect, useMemo, useState } from "react";
import {
	Redirect,
	Route,
	Switch,
	useHistory,
	useRouteMatch,
} from "react-router-dom";
import featureApi from "api/featureApi";
import withAuth from "guards/auth.hoc";
import { convertAddress } from "ultis";
import { useUIContext } from "pages/ui-context";
import HistoryMenu from "./components/HistoryMenu";
import OrderTable from "./components/OrderTable";
import RefundTable from "./components/RefundTable";
import "./index.scss";
import withHeader from "hocs/withHeader";

const History = () => {
	const { address, userIsConnected } = useUIContext();
	const [orderHistory, setOrderHistory] = useState([]);
	const [refundHistory, setRefundHistory] = useState([]);
	const [loading, setLoading] = useState(true);
	const { url } = useRouteMatch();
	const history = useHistory();
	const filter = useMemo(() => {
		const arrayUrl = history.location.pathname.split("/");
		const page = arrayUrl[3];
		if ((isNaN(page) && !(page === undefined)) || arrayUrl.length >= 5) {
			history.replace("/not-found");
		}

		return {
			page: Number.parseInt(page) || 1,
		};
	}, [history.location.pathname]);

	const handlePagination = (e) => {
		filter.page = e;
		if (history.location.pathname.includes("/history/order")) {
			history.push({ pathname: `${url}/order/${e}` });
		} else if (history.location.pathname.includes("/history/refund")) {
			history.push({ pathname: `${url}/refund/${e}` });
		}
	};

	const handleLoading = () => {
		setLoading(true);
	};

	useEffect(() => {
		if (userIsConnected) {
			(async () => {
				try {
					const addressConvert = convertAddress(address);
					if (history.location.pathname.includes("/history/order")) {
						const data = await featureApi.getOrderHistory(
							addressConvert,
							filter.page
						);
						setOrderHistory(data);
					} else if (
						history.location.pathname.includes("/history/refund")
					) {
						const data = await featureApi.getRefundHistory(
							addressConvert,
							filter.page
						);
						setRefundHistory(data);
					}
					setTimeout(() => setLoading(false), 3000);
				} catch (error) {
					console.log(error);
					setLoading(false);
				}
			})();
		}
	}, [address, userIsConnected, filter, history.location.pathname]);

	return (
		<div className="history">
			<div className="history__head">
				<h1 className="m-0 pt-lg headline3">History</h1>
				<p className="m-0 mt-md headline6">{address}</p>
			</div>
			<div className="history__body user-select-none mt-lg">
				<div className={"_mgbt-5"}>
					<HistoryMenu handleLoading={handleLoading} />
				</div>
				<Switch>
					<Route exact path={url}>
						<Redirect from={url} to={`${url}/order`} />
					</Route>
					<Route path={`${url}/order`}>
						<OrderTable
							data={orderHistory}
							onPagination={handlePagination}
							loading={loading}
						/>
					</Route>

					<Route path={`${url}/refund`}>
						<RefundTable
							data={refundHistory}
							onPagination={handlePagination}
							loading={loading}
						/>
					</Route>
					<Route>
						<Redirect to="/not-found" />
					</Route>
				</Switch>
			</div>
		</div>
	);
};

export default withHeader(withAuth(History));
