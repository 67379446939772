import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";
import "./styles.scss";

function HistoryMenu({ handleLoading }) {
	const { url } = useRouteMatch();
	const history = useHistory();
	const [orderUrl, setOrderUrl] = useState(`${url}/order`);
	const [refundUrl, setRefundUrl] = useState(`${url}/refund`);

	useEffect(() => {
		if (history.location.pathname.includes("/history/order")) {
			setOrderUrl(history.location);
		} else if (history.location.pathname.includes("/history/refund")) {
			setRefundUrl(history.location);
		}
	}, [history.location.pathname]);

	return (
		<ul className="menu">
			<li className="item order">
				<NavLink onClick={handleLoading} to={orderUrl}>
					Order History
				</NavLink>
			</li>
			<li className="item refund">
				<NavLink onClick={handleLoading} to={refundUrl}>
					Refund History
				</NavLink>
			</li>
		</ul>
	);
}

export default React.memo(HistoryMenu);
