import React, { Fragment, useEffect, useState } from "react";
import { Empty, Table } from "antd";
import moment from "moment";
import { MULTICHAIN_SUPPORT_SCAN_LINK } from "constants/multichain";
import { truncateAddressMid } from "ultis";
import { useUIContext } from "pages/ui-context";
import AmountLabel from "pages/history/components/AmountLabel";
import SkeletonTable from "../SkeletonTable";

const listStatus = [
	{
		null: "Processing",
		0: "Completed",
		4: "Rejected & \nNot Refund",
		5: "No Token Pair",
	},
	{
		null: "warning",
		0: "success",
		4: "notfound",
		5: "notfound",
	},
];

const OrderTable = ({ data, onPagination, className, loading }) => {
	const [listDataSantizing, setListDataSantizing] = useState([]);
	const { listTokenPairs } = useUIContext();

	const renderTitle = (title) => <span>{title}</span>;

	const columns = [
		{
			title: renderTitle("Status"),
			dataIndex: "status",
			key: "status",
			width: "10%",
			render: (status) => (
				<div>
					<span className="title">Status</span>
					<div className="status">
						<div
							className={`around ${
								listStatus[1][status] || listStatus[1][null]
							}`}
						></div>
						<span>
							{listStatus[0][status] || listStatus[0][null]}
						</span>
					</div>
				</div>
			),
		},
		{
			title: renderTitle("Amount /Form (Network)"),
			dataIndex: "from",
			key: "from",
			width: "20%",
			render: (from) => {
				const truncate = from.network.length > 3 ? 6 : 8;
				return (
					<div>
						<span className="title">Amount/From(Network)</span>
						<div>
							<AmountLabel
								data={{
									amount: from.amount,
									// decimals: from.decimals,
									network: from.network,
									tokenAddress: from.tokenAddress,
									symbol: from.currency,
								}}
							/>
							<span className="span1">
								&#8196;{from.currency}
							</span>
						</div>
						<div>
							<span className="span2">
								{truncateAddressMid(
									from.address,
									truncate,
									truncate
								)}
							</span>
							<span className="ml-2xsm uppercase span2">
								&#8203;({from.network})
							</span>
						</div>
					</div>
				);
			},
		},
		{
			title: renderTitle("Amount Received"),
			dataIndex: "to",
			key: "to",
			width: "20%",
			render: (to) => {
				const truncate = to.network.length > 3 ? 6 : 8;
				return (
					<div>
						<span className="title">Amount Received</span>
						<div>
							<AmountLabel
								data={{
									amount: to.amount,
									// decimals: to.decimals,
									network: to.network,
									tokenAddress: to.tokenAddress,
									symbol: to.currency,
								}}
							/>
							<span className="span1">&#8196;{to.currency}</span>
						</div>
						<div>
							<span className="span2">
								{truncateAddressMid(
									to.address,
									truncate,
									truncate
								)}
							</span>
							<span className="ml-2xsm uppercase span2">
								&#8203;({to.network})
							</span>
						</div>
					</div>
				);
			},
		},
		{
			title: renderTitle("Protocol Fee/ Created Time"),
			dataIndex: "feeAndTime",
			key: "feeAndTime",
			width: "20%",
			render: (feeAndTime) => (
				<div>
					<span className="title">Protocol Fee/Created Time</span>
					<div>
						<AmountLabel
							data={{
								amount: feeAndTime.amount,
								// decimals: feeAndTime?.decimals,
								network: feeAndTime.network,
								tokenAddress: feeAndTime.tokenAddress,
								symbol: feeAndTime.currency,
							}}
						/>
						<span className="span1">
							&#8196;{feeAndTime.currency}
						</span>
					</div>
					<span className="span2">
						{moment(feeAndTime.createDate).format(
							"YYYY-MM-DD HH:mm:ss"
						)}
					</span>
				</div>
			),
		},
		{
			title: renderTitle("Order ID"),
			dataIndex: "orderId",
			key: "orderId",
			width: "20%",
			render: ({ txHash, scanLink }) => (
				<div>
					<a
						href={`${scanLink}/tx/${txHash}`}
						target="_blank"
						rel="noreferrer"
					>
						<span className="title">Order ID</span>
						<span className="span1">
							{truncateAddressMid(txHash, 8, 8)}
						</span>
					</a>
				</div>
			),
		},
	];

	const dataSantizing = () => {
		const res = data.docs.map((record, index) => {
			let scanLink = "";
			let txHash = "";
			const matchTokenPair = listTokenPairs.find(
				(pair) =>
					record.blockchainName === pair.from_chain &&
					record.chainName === pair.to_chain &&
					record.symbol === pair.from_symbol
			);
			if (record.interChainTransactionHash) {
				scanLink = MULTICHAIN_SUPPORT_SCAN_LINK[record.chainName];
				txHash = record.interChainTransactionHash;
			} else {
				scanLink = MULTICHAIN_SUPPORT_SCAN_LINK[record.blockchainName];
				txHash = record.transactionHash;
			}
			return {
				key: index + "_" + record._id,
				status: record.status,
				from: {
					amount: record.value,
					currency: record.symbol,
					address: record.from,
					network: record.blockchainName,
					tokenAddress: matchTokenPair?.from_address || record.token,
					// decimals: pairDecimal?.from,
				},
				to: {
					amount: record.received_value,
					currency: record.toSymbol || record.symbol,
					address:
						record.chainName === "stellar" && record.extraData
							? record.extraData
							: record.to,
					network: record.chainName,
					tokenAddress:
						matchTokenPair?.to_address || record.tokenDestination,
					// decimals: pairDecimal?.to,
				},
				feeAndTime: {
					amount: record.fee,
					currency: record.toSymbol || record.symbol,
					createDate: record.insert_date,
					network: record.chainName,
					tokenAddress:
						matchTokenPair?.to_address ||
						record.receipt?.logs[0]?.address ||
						record.tokenDestination,
					// decimals: pairDecimal?.to,
				},
				orderId: {
					txHash,
					scanLink,
				},
			};
		});
		setListDataSantizing(res);
	};

	useEffect(() => {
		if (listTokenPairs.length && data?.docs?.length) {
			dataSantizing();
			// if (!listTokenPairs[0].decimal_from) {
			//   const reset = setInterval(() => {
			//     dataSantizing();
			//   }, 1000);
			//   return () => clearInterval(reset);
			// }
		}
	}, [listTokenPairs, data]);

	return (
		<Fragment>
			{loading ? (
				<SkeletonTable columns={columns} rowCount={5} />
			) : (
				<Table
					className={className}
					columns={columns}
					dataSource={listDataSantizing}
					locale={
						<Empty
							description="You have no history."
							image={Empty.PRESENTED_IMAGE_SIMPLE}
						/>
					}
					pagination={{
						disabled: !!!data.docs?.length,
						current: data.page,
						defaultCurrent: data.page,
						currency: data.page,
						total: data.totalDocs,
						pageSize: data.limit,
						hideOnSinglePage: true,
						onChange: onPagination,
						showSizeChanger: false,
						responsive: true,
					}}
				/>
			)}
		</Fragment>
	);
};

export default OrderTable;
