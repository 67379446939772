import { groupBy } from "lodash";
import { useUIContext } from "pages/ui-context";
import { useEffect, useState } from "react";
import { getTokenImageAndName } from "ultis";

export default function useWrappedPair() {
	const { listTokenPairs } = useUIContext();
	const [wrappedTokens, setWrappedTokens] = useState([]);
	const [listImage, setListImage] = useState([]);

	useEffect(async () => {
		const groupTokens = groupBy(listTokenPairs, (e) => e.from_symbol);
		const { listImage } = await getTokenImageAndName();
		// Object.keys(groupTokens).map((key) => {
		//   const nativeTokens = groupTokens[key].filter((token) =>
		//     isNativeToken(token)
		//   );
		//   const wrappedTokens = groupTokens[key].filter(
		//     (token) => !isNativeToken(token)
		//   );
		//   groupTokens[key] = { nativeTokens, wrappedTokens };
		// });
		setWrappedTokens(groupTokens);
		setListImage(listImage);
	}, [listTokenPairs]);

	return { wrappedTokens, listImage };
}
