import { MULTICHAIN_SUPPORT_ID } from "./multichain";
import { isConnected, getPublicKey, getNetwork } from "@stellar/freighter-api";

export const WALLET = {
	binanceChain: {
		isActive: true,
		key: "binanceChain",
		chain: "ethereum",
		name: "Binance Chain Wallet",
		logo: "/images/icon/icon-binance-currency.svg",
		icon: "/images/logo/logo-bsc.svg",
		injectedObject: "BinanceChain",
		request: {
			connect: () =>
				window.BinanceChain.request({
					method: "eth_accounts",
				}),
			network: () =>
				window.BinanceChain.request({
					method: "net_version",
				}),
			sendTransaction: (params) =>
				window.BinanceChain.request({
					method: "eth_sendTransaction",
					params,
				}),
		},
		methods: {
			switchNetwork: (chainId) => {
				const chain = Object.keys(MULTICHAIN_SUPPORT_ID).find(
					(item) => MULTICHAIN_SUPPORT_ID[item].hex == chainId
				);
				return window.BinanceChain.switchNetwork(
					MULTICHAIN_SUPPORT_ID[chain].bscChainSwitch
				);
			},
		},
		installation_link:
			"https://chrome.google.com/webstore/detail/binance-chain-wallet/fhbohimaelbohpjbbldcngcnapndodjp?utm_source=chrome-ntp-icon",
	},
	metamask: {
		isActive: true,
		key: "metamask",
		chain: "ethereum",
		name: "Metamask",
		logo: "/images/logo/logo-metamask-white.svg",
		icon: "/images/logo/logo-metamask.svg",
		injectedObject: "ethereum",
		deeplink: "https://metamask.app.link/dapp/",
		request: {
			connect: () =>
				window.ethereum.request({
					method: "eth_requestAccounts",
				}),
			network: () =>
				window.ethereum.request({
					method: "net_version",
				}),
			sendTransaction: (params) =>
				window.ethereum.request({
					method: "eth_sendTransaction",
					params,
				}),
		},
		methods: {
			switchNetwork: (chainId) =>
				window.ethereum.request({
					method: "wallet_switchEthereumChain",
					params: [{ chainId: chainId }],
				}),
		},
		installation_link:
			"https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?utm_source=chrome-ntp-icon",
	},
	freighter: {
		isActive: true,
		key: "freighter",
		chain: "stellar",
		isInstalled: isConnected,
		name: "Freighter",
		logo: "/images/logo/logo-freighter.svg",
		icon: "/images/logo/logo-freighter.svg",
		injectedObject: null, //unknown
		request: {
			connect: () => getPublicKey(),
			network: () => getNetwork(),
		},
		installation_link:
			"https://chrome.google.com/webstore/detail/freighter/bcacfldlkkdogcmkkibnjlakofdplcbk",
	},
};
