import React from "react";
import { Link } from "react-router-dom";
import { ToAbsoluteUrl } from "ultis";
import "./styles.scss";

const ErrorPage = () => {
	return (
		<div className="error-container">
			<img
				className="error-container__img"
				src={ToAbsoluteUrl("/images/img/image-404.svg")}
			/>
			<div className="error-container__text">
				Sorry! The page you’re looking for cannot be found.
			</div>
			<Link className="redirect-btn headline6 rounded-md" to="/">
				Go to Homepage
			</Link>
		</div>
	);
};

export default ErrorPage;
