import { message } from "antd";
import React, {
	createContext,
	useContext,
	useReducer,
	useState,
	useMemo,
	useEffect,
	useCallback,
} from "react";
import { MULTICHAIN_SUPPORT_ID } from "constants/multichain";
import { WALLET } from "constants/walletExtension";
import {
	useNetworkMatch,
	useGetListTokenPair,
	useGetTokenDecimal,
} from "hook/ultis.hook";
import { isMobile } from "react-device-detect";
import {
	GetFromSessionStorage,
	getWeb3WalletInstance,
	RemoveFromSessionStorage,
	SaveToSessionStorage,
} from "ultis";
import { BSC_PROVIDERS } from "../constants";

const UIContext = createContext();

export function useUIContext() {
	return useContext(UIContext);
}

export const UIConsumer = UIContext.Consumer;

const getCurrentWallet = () => {
	const storedWallet = GetFromSessionStorage("walletConnected");
	if (!storedWallet) return null;

	const matchWallet = Object.keys(WALLET).find(
		(walletName) => WALLET[walletName].name === storedWallet
	);
	return WALLET[matchWallet] || WALLET.metamask;
};

const getCurrentNetwork = () => {
	const storedNetwork = GetFromSessionStorage("networkConnected");
	if (!storedNetwork) return MULTICHAIN_SUPPORT_ID.evrynet;
	const matchNetwork = Object.keys(MULTICHAIN_SUPPORT_ID).find(
		(networkId) => MULTICHAIN_SUPPORT_ID[networkId].name === storedNetwork
	);
	return MULTICHAIN_SUPPORT_ID[matchNetwork] || MULTICHAIN_SUPPORT_ID.evrynet;
};

function UIProvider({ children }) {
	const [address, setAddress] = useState("");
	const userIsConnected = useMemo(() => address.length > 0, [address]);

	const [walletWarning, setWalletWarning] = useState(false);
	const [wallet, setWallet] = useState(getCurrentWallet());
	const [network, setNetwork] = useState(getCurrentNetwork());
	const [web3Instance, setWeb3Instance] = useState(null);

	const { networkMatch, walletNetwork } = useNetworkMatch(wallet, network);
	const { listTokenPairs } = useGetListTokenPair();
	useGetTokenDecimal({ listTokenPairs, userIsConnected });

	const connectWallet = useCallback(
		async (currentWallet = wallet, currentNetwork = network) => {
			try {
				const account = await currentWallet.request.connect();
				if (account[0]) {
					if (currentWallet.methods?.switchNetwork && !isMobile) {
						const [_, error] = await switchNetwork(
							currentWallet,
							currentNetwork
						);
						if (error) {
							return [null, error];
						}
					}
					SaveToSessionStorage("loggedWallet", 1);
					SaveToSessionStorage("walletConnected", currentWallet.name);
					SaveToSessionStorage(
						"networkConnected",
						currentNetwork.name
					);
					setWallet(currentWallet);
					setNetwork(currentNetwork);
					if (typeof account === "string") {
						if (isMobile)
							SaveToSessionStorage("accountConnected", account);
						setAddress(account);
					} else {
						if (isMobile)
							SaveToSessionStorage(
								"accountConnected",
								account[0]
							);
						setAddress(account[0]);
					}
				}
				return [1, null];
			} catch (error) {
				if (GetFromSessionStorage("loggedWallet")) {
					RemoveFromSessionStorage("loggedWallet");
					RemoveFromSessionStorage("walletConnected");
					RemoveFromSessionStorage("networkConnected");
					if (isMobile) RemoveFromSessionStorage("accountConnected");
				}
				return [null, error];
			}
		},
		[wallet, network, isMobile]
	);

	const reconnectWallet = useCallback(
		async (wallet, network) => {
			if (isMobile) {
				const storedAccount = GetFromSessionStorage("accountConnected");
				if (storedAccount) {
					setAddress(storedAccount);
					return;
				}
			}
			try {
				const account = await wallet.request.connect();
				if (account[0]) {
					if (typeof account === "string") {
						if (isMobile)
							SaveToSessionStorage("accountConnected", account);
						setAddress(account);
						setWallet(wallet);
						setNetwork(network);
					} else {
						if (isMobile)
							SaveToSessionStorage(
								"accountConnected",
								account[0]
							);
						setAddress(account[0]);
						setWallet(wallet);
						setNetwork(network);
					}
				}
			} catch (error) {
				console.log("reconnect error", error);
				if (GetFromSessionStorage("loggedWallet")) {
					RemoveFromSessionStorage("loggedWallet");
					RemoveFromSessionStorage("walletConnected");
					RemoveFromSessionStorage("networkConnected");
					if (isMobile) RemoveFromSessionStorage("accountConnected");
				}
			}
		},
		[isMobile]
	);

	const tryAddNetworkBSC = useCallback(async () => {
		try {
			const bscNetwork = MULTICHAIN_SUPPORT_ID.bsc;
			const params = [
				{
					chainId: bscNetwork.hex,
					chainName: bscNetwork.name,
					nativeCurrency: {
						name: bscNetwork.nativeCurrency?.name,
						symbol: bscNetwork.nativeCurrency?.symbol,
						decimals: bscNetwork.nativeCurrency?.decimals,
					},
					rpcUrls: bscNetwork.rpcUrls,
					blockExplorerUrls: bscNetwork.blockExplorerUrls,
				},
			];
			await Promise.any(
				BSC_PROVIDERS.map(
					(rpcUrl) =>
						new Promise((resolve, reject) => {
							const newparams = JSON.parse(
								JSON.stringify(params)
							);
							newparams[0].rpcUrls = [rpcUrl];
							window.ethereum
								.request({
									method: "wallet_addEthereumChain",
									params: newparams,
								})
								.then((res) => resolve(rpcUrl))
								.catch((error) => reject(error));
						})
				)
			);
			SaveToSessionStorage("networkConnected", bscNetwork.name);
			if (!isMobile) setNetwork(bscNetwork);
			return [1, null];
		} catch (error) {
			console.log(error);
			return [null, error];
		}
	}, []);

	const addNetwork = useCallback(
		async (currentNetwork = network) => {
			const params = [
				{
					chainId: currentNetwork?.hex,
					chainName: currentNetwork?.name,
					nativeCurrency: {
						name: currentNetwork?.nativeCurrency?.name,
						symbol: currentNetwork?.nativeCurrency?.symbol,
						decimals: currentNetwork?.nativeCurrency?.decimals,
					},
					rpcUrls: currentNetwork?.rpcUrls,
					blockExplorerUrls: currentNetwork?.blockExplorerUrls,
				},
			];
			try {
				await window.ethereum.request({
					method: "wallet_addEthereumChain",
					params,
				});
				SaveToSessionStorage("networkConnected", currentNetwork.name);
				// setNetwork(currentNetwork);
				return [1, null];
			} catch (error) {
				if (
					error.code === -32603 &&
					currentNetwork.networkSignature === "bsc"
				) {
					const [_, tryError] = await tryAddNetworkBSC();
					if (tryError) return [null, tryError];
					return [1, null];
				}
				return [null, error];
			}
		},
		[tryAddNetworkBSC, network]
	);

	const switchNetwork = useCallback(
		async (currentWallet = wallet, currentNetwork = network) => {
			const currentWalletNetwork = await currentWallet.request.network();
			if (currentWalletNetwork === currentNetwork?.decimal) {
				SaveToSessionStorage("networkConnected", currentNetwork.name);
				setNetwork(currentNetwork);
				return [1, null];
			}
			try {
				await currentWallet.methods.switchNetwork(currentNetwork.hex);
				SaveToSessionStorage("networkConnected", currentNetwork.name);
				// setNetwork(currentNetwork);
				return [1, null];
			} catch (switchError) {
				if (switchError.code === 4902 || switchError.code === -32603) {
					const [_, addError] = await addNetwork(currentNetwork);
					if (addError) return [null, addError];
					return [1, null];
				} else return [null, switchError];
			}
		},
		[wallet, network, addNetwork]
	);

	const disconnect = useCallback(() => {
		RemoveFromSessionStorage("loggedWallet");
		RemoveFromSessionStorage("walletConnected");
		RemoveFromSessionStorage("networkConnected");
		if (isMobile) RemoveFromSessionStorage("accountConnected");
		setWallet(null);
		setAddress("");
		setWeb3Instance(null);
	}, [isMobile]);

	const scrollToTop = useCallback(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	}, []);

	useEffect(() => {
		window.addEventListener("load", scrollToTop);
		return () => window.removeEventListener("load", scrollToTop);
	}, [networkMatch]);

	useEffect(() => {
		const storedWallet = getCurrentWallet();
		const storedNetwork = getCurrentNetwork();
		const handleReconnect = (isWalletAvaiable) => {
			if (isWalletAvaiable && storedNetwork) {
				reconnectWallet(storedWallet, storedNetwork);
				return;
			}
			disconnect();
		};
		if (GetFromSessionStorage("loggedWallet") && !address.length) {
			if (storedWallet.chain === "ethereum") {
				if (web3Instance) handleReconnect(true); // if web3 instance !== null provider object has been injected
				return;
			}
			handleReconnect(storedWallet?.isInstalled());
		}
	}, [address, web3Instance]);

	useEffect(() => {
		if (wallet) {
			const hanldeChainChange = (chainId) => {
				window.location.reload(true);
			};
			const handleAccountChange = (account) => {
				if (account && account.length > 0) {
					if (typeof account === "string") {
						if (isMobile)
							SaveToSessionStorage("accountConnected", account);
						// setAddress(account);
					} else {
						if (isMobile)
							SaveToSessionStorage(
								"accountConnected",
								account[0]
							);
						// setAddress(account[0]);
					}
				} else {
					RemoveFromSessionStorage("loggedWallet");
				}
				// if (isMobile) {
				window.location.reload(true);
				// }
			};

			// if web3 instance !== null provider object has been injected
			if (web3Instance) {
				window[wallet?.injectedObject]?.on(
					"chainChanged",
					hanldeChainChange
				);
				window[wallet?.injectedObject]?.on(
					"accountsChanged",
					handleAccountChange
				);
				return () => {
					if (window[wallet?.injectedObject]?.removeListener) {
						window[wallet?.injectedObject]?.removeListener(
							"chainChanged",
							hanldeChainChange
						);
						window[wallet?.injectedObject]?.removeListener(
							"accountsChanged",
							handleAccountChange
						);
					}
				};
			}
		}
	}, [wallet, web3Instance, isMobile]);

	useEffect(() => {
		if (wallet && wallet.chain === "ethereum") {
			if (web3Instance) return;
			const newWeb3Instance = getWeb3WalletInstance(wallet);
			if (newWeb3Instance) {
				setWeb3Instance(newWeb3Instance);
				return;
			}
			const reset = setInterval(() => {
				const Instance = getWeb3WalletInstance(wallet);
				setWeb3Instance(Instance);
			}, 500);
			return () => clearInterval(reset);
		}
	}, [wallet, web3Instance]);

	const value = {
		walletWarning,
		setWalletWarning,
		wallet,
		web3Instance,
		setWallet,
		connectWallet,
		addNetwork,
		network,
		setNetwork,
		switchNetwork,
		disconnect,
		listTokenPairs,
		address,
		networkMatch,
		walletNetwork,
		userIsConnected,
		isMobile,
		// state,
		// dispatch,
	};

	return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
}

export default UIProvider;
