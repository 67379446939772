import { Image } from "components";
import withHeader from "hocs/withHeader";
import { useWindowResizeMobile } from "hook/ultis.hook";
import { useUIContext } from "pages/ui-context";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useHistory, useParams } from "react-router";
import { ToAbsoluteUrl } from "ultis";
import "./styles.scss";

const isHtmlTag = (content) => {
	const htmlTagSignature = "<!DOCTYPE html>";
	return (
		content.includes(htmlTagSignature) ||
		content.includes(htmlTagSignature.toLowerCase())
	);
};

const DocumentHandle = () => {
	const [markdown, setMarkdown] = useState("");
	const params = useParams();
	const { document, rest } = params;
	const history = useHistory();
	const { isMobile } = useUIContext();
	const isMobileScreen = useWindowResizeMobile(720);
	const isMobileDevice = isMobile || isMobileScreen;
	useEffect(() => {
		if (document) {
			if (rest) history.replace("/not-found");
			const url = `/legaldocs/${document}.md`;
			fetch(ToAbsoluteUrl(url))
				.then((response) => {
					if (response.status === 404)
						throw new Error("File not exist");
					return response.text();
				})
				.then((text) => {
					if (isHtmlTag(text))
						throw new Error("Can not use a html file");
					setMarkdown(text);
				})
				.catch((_) => {
					history.replace("/not-found");
				});
		}
	}, [history, document, rest]);

	return (
		<div className="container document-handle">
			<ReactMarkdown
				className={isMobileDevice ? "pt-lg pb-2xl" : "pt-2xl pb-7xl"}
				children={markdown}
				skipHtml={false}
				linkTarget="_blank"
				components={{
					h1: ({ node, ...props }) => (
						<h1
							// className={isMobile ? 'headline2 text-center' : 'headline1 mb-xl'}
							className={
								isMobileDevice
									? "headline2 text-center"
									: "headline1 mb-xl"
							}
							style={{ color: "white" }}
							{...props}
						></h1>
					),
					h2: ({ node, ...props }) => (
						<h2
							className={`${
								isMobileDevice
									? "headline4 mb-md"
									: "headline3 mb-xl"
							}`}
							style={{ color: "white" }}
							{...props}
						></h2>
					),
					h3: ({ node, ...props }) => (
						<h3
							className={`${
								isMobileDevice ? "headline6" : "headline4"
							} mb-md`}
							{...props}
						></h3>
					),
					p: ({ node, ...props }) => (
						<p
							className={`${
								isMobileDevice
									? "bodyLarge pb-sm"
									: "headline6 pb-xl"
							} mb-none ml-xsm`}
							{...props}
						></p>
					),
					img: ({ node, ...props }) => (
						<div
							className={`text-center image-ctn ${
								isMobileDevice ? "mb-sm" : "mb-xl"
							}`}
						>
							<Image className="rounded-lg" {...props} />
						</div>
					),
					ul: ({ node, ...props }) => (
						<ul
							className={`${
								isMobileDevice ? "ml-lg mb-md" : "ml-2xl mb-xl"
							}`}
							{...props}
						></ul>
					),
					ol: ({ node, ...props }) => (
						<ol
							className={`${
								isMobileDevice ? "ml-lg" : "ml-2xl"
							} mb-xl`}
							{...props}
						></ol>
					),
					li: ({ node, ...props }) => (
						<li
							className={`${
								isMobileDevice ? "bodyLarge-sm" : "bodyLarge"
							} mt-sm
            mt-sm`}
							{...props}
						></li>
					),
					a: ({ node, href, children, ...props }) => {
						let link = href;
						let content = children;
						if (link.includes("_replace"))
							link = link.replace("_replace", window.origin);
						if (children[0].includes("_replace"))
							children[0] = children[0].replace(
								"_replace",
								window.origin
							);
						return (
							<a href={link} {...props}>
								{content}
							</a>
						);
					},
				}}
			></ReactMarkdown>
		</div>
	);
};

export default withHeader(DocumentHandle);
