import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Input } from 'antd';
import {
  Image,
  ModalConnectNetwork,
  ModalSelectNetwork,
  ModalSelectToken,
} from 'components';
import { MULTICHAIN_SUPPORT_ID, NETWORK_ORDER } from 'constants/multichain';
import { useUIContext } from 'pages/ui-context';
import { useGetTokenBalance } from 'hook/ultis.hook';
import { addTokenToMetaMask, isNativeToken, ToAbsoluteUrl } from 'ultis';
import FormConnected from '../form-connected';

export const NETWORK_LIST = MULTICHAIN_SUPPORT_ID;
const DEFAULT_FROM_NETWORK = NETWORK_LIST.evrynet;
const DEFAULT_TO_NETWORK = NETWORK_LIST.evrynet;

const FormSwap = () => {
  const { wallet, networkMatch, network, userIsConnected, isMobile } =
    useUIContext();
  const [fromNetwork, setFromNetwork] = useState(DEFAULT_FROM_NETWORK);
  const [toNetwork, setToNetwork] = useState(DEFAULT_TO_NETWORK);
  const [assetChoosen, setAssetChoosen] = useState({
    img: '',
    name: '',
    address: '',
    detail: '',
    min: 0,
    max: 0,
    decimals: 0,
  });
  const { balance: tokenBalance } = useGetTokenBalance({
    asset: assetChoosen,
    refreshTime: 3000,
  });

  const [visibleAsset, setVisibleAsset] = useState(false);
  const [visibleFrom, setVisibleFrom] = useState(false);
  const [visibleTo, setVisibleTo] = useState(false);
  const [walletConnect, setWalletConnect] = useState(false);
  const [isLoadingAssets, setIsLoadingAssets] = useState(false);

  const handleSwitchNetwork = useCallback(() => {
    setFromNetwork(toNetwork);
    setToNetwork(fromNetwork);
  }, [fromNetwork, toNetwork]);

  const onCancelAsset = useCallback(() => {
    setVisibleAsset(false);
  }, []);

  const onCancelFrom = useCallback(() => {
    setVisibleFrom(false);
  }, []);
  const onOpenSelectToNet = useCallback(() => {
    if (isLoadingAssets) return;
    setVisibleTo(true);
  }, [isLoadingAssets]);
  const onCancelTo = useCallback(() => {
    setVisibleTo(false);
  }, []);

  const onCancelWallet = useCallback(() => {
    setWalletConnect(false);
  }, []);

  const handleChangeFromNetwork = useCallback((e) => {
    setFromNetwork(e);
  }, []);

  const handleChangeToNetwork = useCallback(
    (e) => {
      if (isLoadingAssets) return;
      setToNetwork(e);
    },
    [isLoadingAssets, toNetwork]
  );

  const onLoadingToken = useCallback(() => {
    setIsLoadingAssets(true);
  }, []);
  const onDoneLoadToken = useCallback(() => {
    setIsLoadingAssets(false);
  }, []);

  const getAsset = useCallback((asset) => {
    setAssetChoosen({
      name: asset.name,
      img: asset.img,
      detail: asset.detail,
      min: asset.min,
      max: asset.max,
      address: asset.address,
      decimals: asset.decimals,
    });
  }, []);
  const resetAsset = useCallback(() => {
    setAssetChoosen({
      img: '',
      name: '',
      address: '',
      detail: '',
      min: 0,
      max: 0,
      decimals: 0,
    });
  }, []);

  const ConnectedBagde = useCallback(() => {
    if (wallet)
      return (
        <div className='connected-field'>
          {networkMatch ? (
            <>
              <div className='nm-badge is-variant-positive _fs-1 _pdv-2 _h-6 _dp-f _alit-ct'>Connected</div>
            </>
          ) : null}
        </div>
      );
    return null;
  }, [wallet, networkMatch]);

  const checkIsCurrentNetwork = useCallback(
    (selectedNetwork) => {
      if (selectedNetwork?.name === network?.name) return true;
      return false;
    },
    [network]
  );

  const handleAddToken = useCallback(async () => {
    const [_, error] = await addTokenToMetaMask({
      asset: assetChoosen,
      wallet,
      chain: fromNetwork.networkSignature,
    });
  }, [assetChoosen, fromNetwork, wallet]);

  const isConnectedBadgeAtFrom = useMemo(
    () => networkMatch && checkIsCurrentNetwork(fromNetwork),
    [checkIsCurrentNetwork, fromNetwork, networkMatch]
  );

  const isConnectedBadgeAtTo = useMemo(
    () => networkMatch && checkIsCurrentNetwork(toNetwork),
    [checkIsCurrentNetwork, toNetwork, networkMatch]
  );

  const renderConnectedNetworkFrom = useMemo(
    () =>
      userIsConnected && isConnectedBadgeAtFrom ? <ConnectedBagde /> : null,
    [networkMatch, userIsConnected, isConnectedBadgeAtFrom, ConnectedBagde]
  );

  const renderConnectedNetworkTo = useMemo(
    () => (userIsConnected && isConnectedBadgeAtTo ? <ConnectedBagde /> : null),
    [networkMatch, userIsConnected, isConnectedBadgeAtTo, ConnectedBagde]
  );

  useEffect(() => {
    if (network) {
      const fromNet = NETWORK_LIST[network.networkSignature];
      setFromNetwork(fromNet);
    }
  }, [network]);

  useEffect(() => {
    if (network) {
      // if to net, from net being duplicate.
      if (toNetwork?.name === fromNetwork.name) {
        const newNetwork = NETWORK_ORDER.find(
          (e) => e !== fromNetwork.networkSignature && NETWORK_LIST[e].isSupport
        );
        setToNetwork(NETWORK_LIST[newNetwork]);
      }
    }
  }, [fromNetwork]);

  return (
    <>
      <div className='form-swap'>
        <div className='form-container'>
          <div
            className='middle'
            onClick={() => {
              handleSwitchNetwork();
            }}
          >
            <img src={ToAbsoluteUrl('/images/icon/icon-swap-btn.svg')} alt='' />
          </div>
          <div className='form-container__child mt-xl'>
            <div
              className='from-to-network-container u-input-style is-v2'
              onClick={() => setVisibleFrom(true)}
            >
              <div className='select-token-group__text _mgbt-5'>Source</div>
              <Image
                className='image-icon field-icon'
                src={ToAbsoluteUrl(fromNetwork?.icon)}
                alt=''
              />
              <div className='networkname-container'>
                <div className=' network-name leftname'>
                  {fromNetwork?.networkName}
                </div>
                <img
                  src={ToAbsoluteUrl('/images/icon/icon-select-arrow-down.svg')}
                  alt=''
                  className='drop-down-icon'
                />
              </div>
            </div>
            <div
              className={`from-to-network-container u-input-style is-v2 ${
                isLoadingAssets ? 'wait' : ''
              }`}
              onClick={onOpenSelectToNet}
            >
              <div className='select-token-group__text _mgbt-5'>Target</div>
              <Image
                className='image-icon field-icon'
                src={ToAbsoluteUrl(toNetwork?.icon)}
                alt=''
              />
              <div className='networkname-container'>
                <div className=' network-name leftname'>
                  {toNetwork?.networkName}
                </div>
                <img
                  src={ToAbsoluteUrl('/images/icon/icon-select-arrow-down.svg')}
                  alt=''
                  className='drop-down-icon'
                />
              </div>
              {/* {renderConnectedNetworkTo} */}
            </div>
          </div>
          <div className='select-token-group from-to-network-mobile-container'>
            <div className={'_dp-f _alit-ct _g-3'}>
              <div className='select-token-group__text _mgbt-5'>From</div>
              <div className={'_mgbt-5'}>
                {renderConnectedNetworkFrom}
              </div>
            </div>
            <div
              className='select-token-group__select-field u-input-style'
              onClick={() => setVisibleFrom(true)}
            >
              <Image
                className='image-icon field-icon'
                src={ToAbsoluteUrl(fromNetwork?.icon)}
                alt=''
              />
              <span className='pl-xsm network-name'>
                {fromNetwork?.networkName}
              </span>
              <div className='field'></div>

              <img
                src={ToAbsoluteUrl('/images/icon/icon-select-arrow-down.svg')}
                alt=''
                style={{ marginRight: '15px' }}
                className='drop-down-icon'
              />
            </div>
          </div>

          <div className='select-token-group from-to-network-mobile-container'>
            <div className={'_dp-f _alit-ct _g-3'}>
              <div className='select-token-group__text _mgbt-5'>To</div>
              <div className={'_mgbt-5'}>
                {renderConnectedNetworkTo}
              </div>
            </div>
            <div
              className={`select-token-group__select-field ${
                isLoadingAssets ? 'wait' : ''
              } u-input-style`}
              onClick={onOpenSelectToNet}
            >
              <Image
                className='image-icon field-icon'
                src={ToAbsoluteUrl(toNetwork?.icon)}
                alt=''
              />
              <span className='pl-xsm network-name'>
                {toNetwork?.networkName}
              </span>
              <div className='field'></div>
              <img
                src={ToAbsoluteUrl('/images/icon/icon-select-arrow-down.svg')}
                alt=''
                style={{ marginRight: '15px' }}
                className='drop-down-icon'
              />
            </div>
          </div>
        </div>

        <div className='select-token-group'>
          <div className='select-token-group__text _mgbt-5'>Asset</div>
          <div
            className='select-token-group__select-field u-input-style'
            onClick={() => setVisibleAsset(true)}
          >
            <div className='field'>
              {assetChoosen.name?.length > 0 && !isLoadingAssets ? (
                <>
                  {assetChoosen.img ? (
                    <Image
                      className='image-icon field-icon'
                      src={assetChoosen.img}
                      alt=''
                    />
                  ) : (
                    <span className='default-image image-icon ml-md'>
                      <span className='default-image__text'>
                        {assetChoosen.name}
                      </span>
                    </span>
                  )}
                  <span className='pl-xsm network-name'>
                    {assetChoosen.name}
                  </span>
                </>
              ) : (
                <span>
                  <img
                    src={ToAbsoluteUrl('/images/icon/icon-loading.svg')}
                    className='spinning image-icon field-icon'
                  />
                </span>
              )}
            </div>
            <img
              className='drop-down-icon'
              src={ToAbsoluteUrl('/images/icon/icon-select-arrow-down.svg')}
              alt=''
              style={{ marginRight: '15px' }}
            />
          </div>
          {assetChoosen.address?.length > 0 &&
          userIsConnected &&
          wallet?.name === 'Metamask' &&
          !isNativeToken(assetChoosen.address) &&
          networkMatch ? (
            <div className='select-token-group__add-to-wallet'>
              <span onClick={handleAddToken}>
                <img
                  src={ToAbsoluteUrl('/images/icon/icon-plus-circle.svg')}
                  alt=''
                  className='add-token-icon'
                />
                Add {assetChoosen.name} to MetaMask
              </span>
            </div>
          ) : null}
        </div>

        {userIsConnected ? (
          <FormConnected
            tokenBalance={tokenBalance}
            assetChoosen={assetChoosen}
            fromNetwork={fromNetwork}
            toNetwork={toNetwork}
            isConnectedBadgeAtFrom={isConnectedBadgeAtFrom}
            isConnectedBadgeAtTo={isConnectedBadgeAtTo}
            checkIsCurrentNetwork={checkIsCurrentNetwork}
          />
        ) : (
          <>
            <div
              className='select-token-group'
              style={{ marginBottom: '20px' }}
            >
              <div className='select-token-group__text _mgbt-5'>Amount</div>
              <Input
                className='select-token-group__amount u-input-style'
                type='number'
                inputMode='decimal'
              />
            </div>

            <div className='swap-button'>
              <button
                className='nm-button is-size-large is-pill is-fluid'
                onClick={() => setWalletConnect(true)}
                style={{ cursor: 'pointer' }}
              >
                {isMobile ? 'Connect Wallet' : 'Connect to Wallet'}
              </button>
            </div>
          </>
        )}
      </div>
      <ModalSelectToken
        visible={visibleAsset}
        onCancel={onCancelAsset}
        getAsset={getAsset}
        onLoadingToken={onLoadingToken}
        onDoneLoadToken={onDoneLoadToken}
        resetAsset={resetAsset}
        currentAsset={assetChoosen}
        from_Network={fromNetwork}
        to_Network={toNetwork}
        networkMatch={networkMatch}
      />

      {/* from network */}
      <ModalSelectNetwork
        visible={visibleFrom}
        onCancel={onCancelFrom}
        currentNetwork={fromNetwork}
        onSelected={handleChangeFromNetwork}
      />
      {/* to network */}
      <ModalSelectNetwork
        visible={visibleTo}
        onCancel={onCancelTo}
        currentNetwork={toNetwork}
        onSelected={handleChangeToNetwork}
        disableNetwork={fromNetwork}
      />

      <ModalConnectNetwork visible={walletConnect} onCancel={onCancelWallet} />
    </>
  );
};

export default FormSwap;
