import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Col, Row } from "antd";
import { CONTRACT_ADDRESS, DEFAULT_TOKEN_ICON } from "constants/index";
import { NETWORK_ORDER } from "constants/multichain";
import stellar_api from "api/stellar_api";
import { unionBy } from "lodash";
import { web3HttpInstance } from "ultis";
import { useWindowResizeMobile } from "hook/ultis.hook";
import { useUIContext } from "pages/ui-context";
import useWrappedPair from "./hook/useWrappedPair";
import { Image } from "components";
import AddressShortcut from "./components/AddressShortcut";
import BalanceLabel from "./components/BalanceLabel";
import "./index.scss";
import withHeader from "hocs/withHeader";

const tokenOrder = ["EVRY", "ETH", "BNB", "XLM"];
const web3Instance = web3HttpInstance;
function ProofOfAssets({}) {
	const { wrappedTokens, listImage } = useWrappedPair();
	const { isMobile } = useUIContext();
	const isMobileScreen = useWindowResizeMobile(720);
	const isZoomIn_x4 = useWindowResizeMobile(860);
	const [stellarBalances, setStellarBalances] = useState({});
	const isMobileDevice = useMemo(
		() => isMobileScreen || isMobile,
		[isMobileScreen, isMobile]
	);

	const getStellarBalance = useCallback(async (address) => {
		try {
			const account = await stellar_api.getAccount(address);
			if (account?.balances.length) {
				const balances = account.balances.reduce((acc, cur) => {
					if (cur.asset_code)
						return { ...acc, [cur.asset_code]: cur };
					if (cur.asset_type === "native")
						return { ...acc, XLM: cur };
				}, {});
				setStellarBalances(balances);
				return;
			}
			setStellarBalances({});
		} catch (error) {
			setStellarBalances({});
			console.log(error);
		}
	}, []);

	const getLockedFundStellar = useCallback(
		(symbol, address) => {
			if (stellarBalances) {
				const matchTokenBalance = stellarBalances[symbol];
				if (!matchTokenBalance) return null;
				if (
					matchTokenBalance.asset_issuer === address ||
					matchTokenBalance.asset_type === "native"
				) {
					return matchTokenBalance;
				}
			}
			return null;
		},
		[stellarBalances]
	);

	const renderLockedFund = useCallback(
		(tokenData) => {
			const uniquePair = unionBy(tokenData, (e) =>
				e.from_chain === "stellar"
					? e.from_address.toUpperCase()
					: e.from_address
			);
			return NETWORK_ORDER.map((key) => {
				const pair = uniquePair.find((e) => e.from_chain === key);
				if (!pair) return null;
				let stellar_asset = null;
				const tokenAddress =
					pair.from_chain === "stellar"
						? pair.from_address.toUpperCase()
						: pair.from_address;
				if (pair.from_chain === "stellar") {
					stellar_asset = getLockedFundStellar(
						pair.from_symbol,
						tokenAddress
					);
					if (!stellar_asset) return null;
				}
				return (
					<Row
						key={pair.from_symbol + "_" + pair._id}
						className="wrapped-token-row"
					>
						<Col xs={24}>
							<BalanceLabel
								className="title"
								stellar_asset={stellar_asset}
								symbol={pair.from_symbol}
								address={tokenAddress}
								chain={pair.from_chain}
								web3Instance={web3Instance[pair.from_chain]}
							/>
							<AddressShortcut
								stellar_asset={stellar_asset}
								chain={pair.from_chain}
								address={tokenAddress}
								isMobile={isMobileDevice}
							/>
						</Col>
					</Row>
				);
			});
		},
		[getLockedFundStellar, isMobileDevice]
	);

	const renderListToken = useMemo(() => {
		const listKey = Object.keys(wrappedTokens);
		if (!listKey.length) return null;
		const listToken = tokenOrder.concat(
			listKey.filter((key) => !tokenOrder.some((e) => e === key))
		);
		return listToken.map((key) =>
			wrappedTokens[key] ? (
				<Row className="content-table" key={key}>
					<Col xs={24} xl={13} md={11}>
						<Row className="logo-wrapper">
							{listImage[key] || DEFAULT_TOKEN_ICON ? (
								<Image
									className="logo-currency"
									src={`${
										listImage[key] || DEFAULT_TOKEN_ICON
									}`}
									alt=""
								/>
							) : (
								<span className="default-image logo-currency">
									<span className="default-image__text">
										{key}
									</span>
								</span>
							)}
							<span className="title">{key}</span>
						</Row>
					</Col>
					<Col
						xl={11}
						md={13}
						sm={24}
						xs={24}
						className="wrapped-token-wrapper"
					>
						{renderLockedFund(wrappedTokens[key])}
					</Col>
				</Row>
			) : null
		);
	}, [wrappedTokens, renderLockedFund, listImage, isZoomIn_x4]);

	useEffect(() => {
		if (CONTRACT_ADDRESS.bridgeStellar) {
			getStellarBalance(CONTRACT_ADDRESS.bridgeStellar);
		}
	}, []);

	return (
		<div className="proof-container user-select-none">
			<div className="container">
				{isMobileDevice ? null : (
					<Row className="pt-2 header-table">
						<Col>
							<span className="title">Proof Of Asset</span>
						</Col>
					</Row>
				)}
				{renderListToken}
			</div>
		</div>
	);
}

export default withHeader(ProofOfAssets);
