import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useUIContext } from "pages/ui-context";
import { GetFromSessionStorage } from "ultis";

function AuthGuard({ children }) {
	let history = useHistory();
	const { address } = useUIContext();

	useEffect(() => {
		const logged = GetFromSessionStorage("loggedWallet");
		if (!logged) history.push("/");
	}, [address]);

	return <>{children}</>;
}

export default AuthGuard;
