import { Button, Drawer, Dropdown } from "antd";
import {
	CustomDropdown,
	Image,
	ModalConnectNetwork,
	ModalRejected,
} from "components";
import { NETWORK_ID } from "constants/index.js";
import { MULTICHAIN_SUPPORT_ID, NETWORK_ORDER } from "constants/multichain";
import withGuest from "guards/guest.hoc";
import { useWindowResizeMobile } from "hook/ultis.hook";
import { useUIContext } from "pages/ui-context";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { SaveToSessionStorage, ToAbsoluteUrl, truncateAddressMid } from "ultis";
import "./style.scss";

const ListTargetSantizing = () => {
	let res = [];
	for (const key of NETWORK_ORDER) {
		const value = MULTICHAIN_SUPPORT_ID[key];
		if (value.isSupport)
			res.push({
				key,
				name: value.name,
				icon: value.icon,
				value: value.altName,
			});
	}
	return res;
};

const getSupportNetwork = (walletName, network) => {
	let res = false;
	switch (walletName) {
		case "Metamask": {
			if (network.hex) res = true;
			else res = false;
			break;
		}
		case "Binance Chain Wallet": {
			if (network.bscChainSwitch) res = true;
			else res = false;
			break;
		}
		case "Freighter": {
			if (network.key) res = true;
			else res = false;
			break;
		}
	}
	return res;
};

const Header = () => {
	const {
		address,
		wallet,
		walletNetwork,
		walletWarning,
		networkMatch,
		setNetwork,
		network,
		switchNetwork,
		disconnect,
		userIsConnected,
		isMobile,
		addNetwork,
	} = useUIContext();

	const isMobileScreen = useWindowResizeMobile(932);
	const [listNetwork, setListNetwork] = useState(ListTargetSantizing());
	const [isLoading, setIsLoading] = useState(false);
	const [walletConnect, setWalletConnect] = useState(false);
	const [addNetworkFail, setAddNetworkFail] = useState(false);
	const [isRejected, setIsRejected] = useState(false);
	const [drawerVisiable, setDrawerVisiable] = useState(false);
	const isMobileDevice = useMemo(
		() => isMobileScreen || isMobile,
		[isMobileScreen, isMobile]
	);
	// const location = useLocation();
	// console.log(location);
	const header = useRef();

	const handleCancelConnect = useCallback(() => setWalletConnect(false), []);
	const handleCancelAddFail = useCallback(() => setAddNetworkFail(false), []);
	const handleCancelRejected = useCallback(() => setIsRejected(false), []);
	const handleCancelDrawer = useCallback(() => setDrawerVisiable(false), []);

	const handleDisconnect = useCallback(() => {
		disconnect();
		handleCancelDrawer();
	}, [disconnect, handleCancelDrawer]);

	const handleConnectWallet = useCallback(() => {
		setWalletConnect(true);
		handleCancelDrawer();
	}, [handleCancelDrawer]);

	const handleChangeRightNetwork = useCallback(async () => {
		if (isMobile) return;
		const [_, error] = await switchNetwork();
		if (error) {
			if (error.code === 4001) {
				setIsRejected(true);
				return;
			}
		}
	}, [switchNetwork, isMobile]);

	const handleChangeNetwork = useCallback(
		async (selectedItem) => {
			const newNetwork = MULTICHAIN_SUPPORT_ID[selectedItem?.key];
			if (!userIsConnected || isMobile) {
				SaveToSessionStorage("networkConnected", newNetwork.name);
				setNetwork(newNetwork);
				return [1, null];
			}
			setIsLoading(true);
			if (wallet?.methods?.switchNetwork && !isMobile) {
				const [_, error] = await switchNetwork(wallet, newNetwork);
				if (error) {
					if (error.code === 4001) {
						setIsLoading(false);
						setIsRejected(true);
						return [1, null];
					}
					setIsLoading(false);
					return [null, error];
				}
			} else {
				SaveToSessionStorage("networkConnected", newNetwork.name);
				setNetwork(newNetwork);
			}
			setIsLoading(false);
			return [1, null];
		},
		[switchNetwork, userIsConnected, wallet, isMobile]
	);

	const handleAddNetwork = useCallback(async () => {
		const [_, error] = await addNetwork();
		if (error) {
			if (error.code === 4001) {
				setIsRejected(true);
				return;
			}
			setAddNetworkFail(true);
		}
	}, [addNetwork]);

	const renderHistoryLink = useMemo(
		() =>
			userIsConnected ? (
				<Link to="/history/order" onClick={handleCancelDrawer}>
					<a className={"nm-link history-link _fs-5 _fw-500"}>
						History
					</a>
				</Link>
			) : null,
		[userIsConnected, handleCancelDrawer]
	);

	const renderWalletInfo = useMemo(
		() =>
			userIsConnected ? (
				<Dropdown
					overlay={
						<div className={"_dp-f _jtfct-ct"}>
							<button
								className="nm-button is-size-small _mgt-3"
								onClick={handleDisconnect}
							>
								<span className="disconnect-button__text">
									Disconnect
								</span>
							</button>
						</div>
					}
					trigger={["click"]}
					getPopupContainer={() =>
						!isMobileDevice ? header.current : document.body
					}
				>
					<div className="nm-button is-variant-secondary wallet-connected _bdrd-max _alit-ct">
						<div className="_dp-f _g-4">
							<span className="wallet__icon">
								<img
									src={ToAbsoluteUrl(wallet?.icon)}
									alt=""
									height={20}
								/>
							</span>
							<span className="wallet__text bodyLarge">
								{truncateAddressMid(
									address,
									isMobileDevice ? 4 : 8,
									isMobileDevice ? 5 : 8
								)}
							</span>
						</div>
						{/*<p className='network-name bodyLarge'>{network?.altName}</p>*/}
					</div>
				</Dropdown>
			) : (
				<button
					className="nm-button connect-wallet-btn is-variant-secondary"
					onClick={handleConnectWallet}
				>
					<div className="connect-wallet-btn__icon _mgr-4">
						<img
							src="/images/icon/icon-wallet-w.svg"
							alt="Wallet icon"
						/>
					</div>
					<div className={`connect-wallet-btn__text `}>
						Connect to Wallet
					</div>
				</button>
			),
		[
			address,
			wallet,
			handleDisconnect,
			handleConnectWallet,
			isMobileDevice,
			userIsConnected,
			header,
		]
	);

	const renderNetWorkSelectItem = useCallback(
		(item) => (
			<span className="item-select _dp-f _alit-ct">
				<span className="item-select__icon">
					<Image src={item?.icon} alt={item?.value} />
				</span>
				<span className="item-select__text">{item?.value}</span>
			</span>
		),
		[network, userIsConnected]
	);

	const renderSelectNetwork = useMemo(() => {
		const selected = listNetwork.find(
			(item) => item.value === network?.altName
		);
		return (
			<div className="network-connected">
				<CustomDropdown
					className={"drop-down"}
					listItem={listNetwork}
					dropDownClass={"select-network"}
					onSelectItem={handleChangeNetwork}
					renderItem={renderNetWorkSelectItem}
					isLoading={isLoading}
					selected={selected}
					getPopupContainer={() =>
						header.current ? header.current : document.body
					}
					dropdownIcon={
						<img
							src={ToAbsoluteUrl(
								"/images/icon/icon-select-arrow-down.svg"
							)}
							alt=""
							className="drop-down-icon"
						/>
					}
				/>
			</div>
		);
	}, [
		listNetwork,
		isLoading,
		handleChangeNetwork,
		renderNetWorkSelectItem,
		network,
		header,
	]);
	const renderNetworkWarning = useMemo(() => {
		const matchWalletNetwork = Object.values(NETWORK_ID).find(
			(e) => e.decimal == walletNetwork
		);
		const isWalletAvaiable = wallet?.isInstalled
			? wallet?.isInstalled()
			: window[wallet?.injectedObject] !== undefined;

		if (!networkMatch && !walletWarning && isWalletAvaiable)
			return (
				<div className="network-match">
					<p>
						<span>App network </span>
						<span>
							({isMobile ? network.name : network.decimal})
						</span>
						<span>
							{" "}
							doesn't match to network selected in wallet{" "}
						</span>
						<span>
							(
							{isMobile ? matchWalletNetwork.name : walletNetwork}
							).{" "}
						</span>
						{isMobile ? (
							<span>
								Please change your wallet network setting. If
								you have not add
								{" " + network.name} in your metamask yet, click
								<a
									className="mx-2xsm add-network"
									onClick={handleAddNetwork}
								>
									here
								</a>
								to continue.
							</span>
						) : (
							<>
								<span>
									Learn how to change network in wallet or (2){" "}
								</span>
								<a
									className="change-network"
									onClick={handleChangeRightNetwork}
								>
									Change network
								</a>
							</>
						)}
					</p>
				</div>
			);
	}, [
		isMobile,
		networkMatch,
		walletNetwork,
		walletWarning,
		wallet,
		handleAddNetwork,
		handleChangeRightNetwork,
	]);

	useEffect(() => {
		if (!userIsConnected) setListNetwork(ListTargetSantizing());
		else {
			let newListNetwork = [];
			for (const key of NETWORK_ORDER) {
				const value = MULTICHAIN_SUPPORT_ID[key];
				if (
					value.isSupport &&
					wallet?.chain === value.chain &&
					getSupportNetwork(wallet?.name, value)
				)
					newListNetwork.push({
						key,
						name: value.name,
						icon: value.icon,
						value: value.altName,
					});
			}
			setListNetwork(newListNetwork);
		}
	}, [userIsConnected, wallet]);

	return (
		<>
			<header className="header" ref={header}>
				<div
					className={`header-logo${drawerVisiable ? "--active" : ""}`}
				>
					{isMobileDevice && !drawerVisiable ? (
						<Button
							className="header-logo__menu-button mr-xl p-none pr-3xsm"
							onClick={() => setDrawerVisiable(true)}
						>
							<img src="/images/icon/icon-menu.svg" alt="menu" />
						</Button>
					) : null}
					{!drawerVisiable ? (
						<Link className="header-logo__logo" to="/">
							<Image
								src={ToAbsoluteUrl(
									isMobileDevice
										? "/images/logo/warp-logo-mobile.svg"
										: "/images/logo/warp-logo.svg"
								)}
								alt="warp"
							/>
						</Link>
					) : null}
				</div>
				<div className="_dp-f _alit-ct _g-7 _pdr-7">
					{isMobileDevice ? null : renderHistoryLink}
					{renderSelectNetwork}
					{isMobileDevice ? null : renderWalletInfo}
				</div>
			</header>
			{renderNetworkWarning}
			<Drawer
				title={
					<div
						className={`logo logo${
							drawerVisiable ? "--active" : ""
						}`}
					>
						<Link to="/" className="header-logo__logo">
							<img
								src={ToAbsoluteUrl(
									isMobileDevice
										? "/images/logo/warp-logo-mobile.svg"
										: "/images/logo/warp-logo.svg"
								)}
								alt="warp"
								width={48}
								height={48}
							/>
						</Link>
					</div>
				}
				className="drawer-mobile"
				width="unset"
				placement={"left"}
				closable={false}
				onClose={handleCancelDrawer}
				onBlur={handleCancelDrawer}
				visible={drawerVisiable}
			>
				<div className="btn-group">
					{renderHistoryLink}
					{renderWalletInfo}
				</div>
			</Drawer>

			<ModalConnectNetwork
				visible={walletConnect}
				onCancel={handleCancelConnect}
			/>
			<ModalRejected
				title="Error"
				message={`Your Metamask application does not support this method, you have to add ${network?.name} by manual`}
				visible={addNetworkFail}
				onCancel={handleCancelAddFail}
			/>
			<ModalRejected
				title="Error"
				visible={isRejected}
				message="User rejected"
				onCancel={handleCancelRejected}
			/>
		</>
	);
};

export default withGuest(Header);
