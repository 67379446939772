import React from "react";
import GuestGuard from "./guest.guard";

function withGuest(Comp) {
	return () => (
		<GuestGuard>
			<Comp />
		</GuestGuard>
	);
}

export default withGuest;
