import React from "react";
import {
	HashRouter as Router,
	Redirect,
	Route,
	Switch,
} from "react-router-dom";
import { EvryHubI18nProvider, I18nProvider } from "../layouts/i18n";
import ErrorPage from "./404page";
import EvryHub from "./evry-hub-home-v3";
import History from "./history";
import DocumentHandle from "./document-handle";
import ProofOfAssets from "./proof-of-assets";
import UIProvider from "./ui-context";

function App() {
	return (
		<EvryHubI18nProvider>
			<I18nProvider>
				<UIProvider>
					<Router>
						<Switch>
							<Route exact path="/" component={EvryHub} />
							<Route path="/history" component={History} />
							<Route
								exact
								path="/proof-of-assets"
								component={ProofOfAssets}
							/>
							<Route
								exact
								path={"/documents/:document/:rest?"}
								component={DocumentHandle}
							/>
							<Route path="/not-found" component={ErrorPage} />
							<Redirect to="/not-found" />
						</Switch>
					</Router>
				</UIProvider>
			</I18nProvider>
		</EvryHubI18nProvider>
	);
}

export default App;
