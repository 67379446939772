import React, { useEffect, useState, useMemo } from "react";
import {
	formWei,
	getTokenDecimalEthereum,
	getSingleLineFromCache,
} from "ultis";
import BigNumber from "bignumber.js";
import useBridgeContract from "../../hook/useBridgeContract";
import { DECIMALS_CACHES } from "constants/index";
import { parseInt } from "lodash";
import { useCallback } from "react";
import { zeroCutter } from "ultis";

export default function BalanceLabel({
	className,
	address,
	symbol,
	chain,
	stellar_asset,
	web3Instance,
}) {
	const [balance, setBalance] = useState("0");
	const [retry, setRetry] = useState(false);
	const { bridgeContract } = useBridgeContract(chain, web3Instance);
	const origin = useMemo(() => window.location.origin, []);
	const cacheKey = useMemo(() => `${symbol}/${chain}`, [symbol, chain]);

	const getLockedFundEthereum = useCallback(async () => {
		try {
			const lockedFund = await bridgeContract.methods
				.getLockedFunds(address)
				.call();
			const [decimalData, cacheError] = await getSingleLineFromCache(
				DECIMALS_CACHES,
				origin,
				cacheKey
			);
			let decimals = 0;
			if (decimalData) decimals = parseInt(decimalData.decimals);
			if (cacheError) {
				const [{ tokenDecimal }, error] = await getTokenDecimalEthereum(
					{
						chain: chain,
						token: { address, symbol },
					}
				);
				if (tokenDecimal) decimals = parseInt(tokenDecimal);
				if (error) console.log(error);
			}
			const lockedFund_formWei = formWei(lockedFund, decimals);
			const lockedFund_toBN = new BigNumber(lockedFund_formWei);
			if (
				lockedFund_toBN.c.length > 1 ||
				(lockedFund_toBN.e < 0 && lockedFund_toBN.c.length <= 1)
			) {
				const lockedFund_round = lockedFund_toBN
					.toFixed(decimals > 8 ? 8 : decimals)
					.toString();
				setBalance(zeroCutter(lockedFund_round));
			} else setBalance(lockedFund_toBN.toFixed(0).toString());
			setRetry(false);
		} catch (error) {
			if (error?.message.includes("Invalid JSON RPC response")) {
				setRetry(false);
				return;
			}
			setRetry(true);
		}
	}, [bridgeContract, origin, cacheKey, address, symbol, chain]);

	useEffect(() => {
		switch (chain) {
			case "stellar":
				if (stellar_asset) setBalance(stellar_asset.balance);
				else setBalance("0");
				break;
			default:
				getLockedFundEthereum();
				break;
		}
	}, [chain, stellar_asset]);

	useEffect(() => {
		if (retry) {
			const reset = setInterval(() => {
				getLockedFundEthereum();
			}, 2000);
			return () => clearInterval(reset);
		}
	}, [retry]);

	return (
		<div className={className}>
			<span className={`${className}__balance`}>{balance}</span>{" "}
			<span>{symbol}</span>
		</div>
	);
}
