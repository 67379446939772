import featureApi from "api/featureApi";
import BigNumber from "bignumber.js";
import * as ConstantContract from "constants/index";
import {
	BSC_PROVIDER,
	ETH_PROVIDER,
	EVRYNET_PROVIDER,
	NETWORK_TYPE,
} from "constants/index";
import {
	BRIDGE_TOKEN_ABI,
	MULTICHAIN_SUPPORT_ID,
	MULTICHAIN_SUPPORT_SCAN_LINK,
} from "constants/multichain";
import { chain, parseInt, reject } from "lodash";
import {
	getPublicKey,
	signTransaction,
	getNetwork,
} from "@stellar/freighter-api";
import stellar_api from "api/stellar_api";

import Web3 from "web3";

export const web3HttpInstance = {
	bsc: new Web3(new Web3.providers.HttpProvider(BSC_PROVIDER)),
	eth: new Web3(new Web3.providers.HttpProvider(ETH_PROVIDER)),
	evrynet: new Web3(new Web3.providers.HttpProvider(EVRYNET_PROVIDER)),
};

export const truncateAddressMid = (text = "", from = 35, to = text.length) =>
	`${text.substring(0, from)}...${text.substring(
		text.length - to,
		text.length
	)}`;

export const truncateEnd = (text = "", length, maxLength = 10) =>
	text.length > maxLength ? text.substring(0, length) + "..." : text;

export const hexToInt = (address) => parseInt(address, 16);

export const isNativeToken = (token_address) => {
	return token_address === "0x0000000000000000000000000000000000000000";
};

export const ToAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export const SaveToLocalStorage = (key, data) => {
	localStorage.setItem(key, JSON.stringify(data));
};

export const GetFromLocalStorage = (key) => {
	return JSON.parse(localStorage.getItem(key));
};

export const RemoveFromLocalStorage = (key) => {
	localStorage.removeItem(key);
};

export const SaveToSessionStorage = (key, data) => {
	sessionStorage.setItem(key, JSON.stringify(data));
};

export const GetFromSessionStorage = (key) => {
	const item = sessionStorage.getItem(key);
	if (!item || item === "undefined") {
		return undefined;
	}
	return JSON.parse(item);
};

export const RemoveFromSessionStorage = (key) => {
	sessionStorage.removeItem(key);
};

export const deepCompare = (itemA, itemB) => {
	const itemAToString = JSON.stringify(itemA);
	const itemBToString = JSON.stringify(itemB);
	return itemAToString !== itemBToString;
};

export const openCache = async (cacheName) => {
	try {
		if (window.caches) {
			const cache = await window.caches.open(cacheName);
			return [cache, null];
		}
		return [null, new Error("Cache not available")];
	} catch (error) {
		return [null, error];
	}
};

export const getSingleLineFromCache = async (cacheName, page, key) => {
	try {
		const [cache, error] = await openCache(cacheName);
		if (error) return [null, error];
		const res = await cache.match(`${page}/${key}`);
		if (res && res.ok) {
			const data = await res.json();
			return [data, null];
		}
		return [null, new Error(`${page}/${key} not stored yet`)];
	} catch (error) {
		return [null, error];
	}
};

export const getMultiLineFromCache = async (cacheName, page, listKey) => {
	try {
		const [cache, openError] = await openCache(cacheName);
		if (openError) return [null, openError];
		let res = {};
		for (const key of listKey) {
			const cacheRes = await cache.match(`${page}/${key}`);
			if (cacheRes && cacheRes.ok) {
				const data = await cacheRes.json();
				res[key] = data;
			} else res[key] = null;
		}
		return [res, null];
	} catch (error) {
		return [null, error];
	}
};

export const storeToCache = async (cacheName, key, payload) => {
	try {
		const [cache, error] = await openCache(cacheName);
		if (error) return [null, error];
		const data = new Response(JSON.stringify({ ...payload }));
		await cache.put(key, data);
		return [1, null];
	} catch (error) {
		return [null, error];
	}
};

export const getWeb3WalletInstance = (wallet) => {
	const { injectedObject } = wallet;
	if (window[injectedObject]) return new Web3(window[injectedObject]);
	return null;
};

export const checkNativeCurrency = (tokenName, networkNativeCurr) => {
	if (tokenName === networkNativeCurr) return true;
	return false;
};

export const getSwapNetworkType = (from_chain) => {
	switch (from_chain) {
		case "eth": {
			return 1;
		}
		case "bsc": {
			return 2;
		}
		case "stellar": {
			return 3;
		}
		case "evrynet": {
			return 4;
		}
		default:
			break;
	}
};

export const checkTokenAllow = async (chain, payload) => {
	switch (chain) {
		case "ethereum":
			return await checkTokenAllowEthereum(payload);
		case "stellar":
			break;
		default:
			break;
	}
};

export const checkTokenAllowEthereum = async (payload) => {
	const {
		web3Instance,
		walletAddress,
		bridgeToken_ABI,
		bridgeBankAddress,
		assetChoosen,
		tokenBalance,
	} = payload;
	if (!!!assetChoosen.address || !web3Instance) return;
	try {
		const contr = await new web3Instance.eth.Contract(
			bridgeToken_ABI.default ? bridgeToken_ABI.default : bridgeToken_ABI,
			assetChoosen.address
		);

		const allowSwap = await contr.methods
			.allowance(walletAddress, bridgeBankAddress)
			.call();
		if (tokenBalance) {
			const amountToWei =
				assetChoosen.tokenDecimals != 0
					? web3Instance.utils.toWei(String(tokenBalance), "ether")
					: toWeiNumber(tokenBalance, assetChoosen.tokenDecimals);
			if (allowSwap - amountToWei > 0) return true;
			return false;
		}
		return false;
	} catch (err) {
		return false;
	}
};

export const getUserApproveEthereum = async (payload) => {
	const {
		web3Instance,
		walletAddress,
		bridgeToken_ABI,
		bridgeBankAddress,
		tokenAddress,
		wallet,
	} = payload;
	if (!walletAddress || !web3Instance) return;
	try {
		const contr = await new web3Instance.eth.Contract(
			bridgeToken_ABI.default ? bridgeToken_ABI.default : bridgeToken_ABI,
			tokenAddress
		);

		// const amountToWei = web3ETH.utils.toWei(String(amountToken), 'ether');
		const maxApprove = ConstantContract.MaxUint;

		const approve = await new Promise((resolve, reject) =>
			contr.methods
				.approve(bridgeBankAddress, maxApprove)
				.send(
					{
						from: walletAddress,
						gas: 300000,
					},
					(error, res) => {
						if (wallet?.injectedObject === "BinanceChain") {
							// console.log(error, res);
							if (error) reject(error);
							resolve(res);
						}
					}
				)
				.on("confirmation", (confNumber, receipt, latestBlockHash) =>
					resolve(receipt)
				)
				.on("error", (error) => reject(error))
		);

		// console.log(approve);
		// setTimeout(() => console.log(approve), [5000]);
		// console.log(approve);

		return approve;
	} catch (err) {
		console.log(err);
		return false;
	}
};

export const getUserApprove = (chain, payload) => {
	switch (chain) {
		case "ethereum":
			return getUserApproveEthereum(payload);
		case "stellar":
			return true;
		default:
			break;
	}
};

export const sendContract = async (chain, payload) => {
	switch (chain) {
		case "ethereum":
			return sendContractEthereum(payload);
		case "stellar":
			return sendContractStellar(payload);
		default:
			break;
	}
};

export const retrievePublicKeyStellar = async () => {
	try {
		return await getPublicKey();
	} catch (err) {
		return err;
	}
};

export const userSignTransactionStellar = async (xdr, network) => {
	try {
		const res = await signTransaction(xdr, network);
		return [res, null];
	} catch (err) {
		return [null, err];
	}
};

export const getXdrStellar = async (data) => {
	try {
		const res = await featureApi.sendLockStellar({
			...data,
		});
		return [res, null];
	} catch (error) {
		return [null, error];
	}
};

export const getXdrEvmStellarTransaction = async (data) => {
	try {
		const res = await featureApi.sendLockEvmStellar({
			...data,
		});
		return [res, null];
	} catch (error) {
		return [null, error];
	}
};

export const sendContractToStellar = async (payload) => {
	try {
		const {
			asset,
			walletAddress,
			targetAddr,
			amountToken,
			fromNetwork,
			toNetwork,
			bridgeBankAddress,
			wallet,
		} = payload;
		const lockRequest = {
			blockchainName: fromNetwork.networkSignature,
			chainName: toNetwork,
			sender: walletAddress,
			recipient: targetAddr,
			amount: amountToken,
			token: asset?.address,
		};
		const [resXdr, getEvmXDRError] = await getXdrEvmStellarTransaction(
			lockRequest
		);
		if (getEvmXDRError) throw getEvmXDRError; // if network error and server cant get XDR for tx
		if (resXdr.success) {
			const { xdr } = resXdr;
			const transactionParameters = {
				gas: Web3.utils.toHex("300000"),
				from: walletAddress,
				to: bridgeBankAddress,
				data: xdr,
			};
			if (isNativeToken(asset?.address))
				transactionParameters.value = Web3.utils.toHex(
					toWeiStr(amountToken, asset.decimals?.from)
				);
			const txHash = await wallet.request.sendTransaction([
				transactionParameters,
			]);
			console.log(txHash);
			return txHash;
		}
		throw new Error(resXdr.message); // if payload reach server but cant get XDR for tx
	} catch (err) {
		console.log(err);
		throw err; // pass error
	}
};

export const sendContractStellar = async (payload) => {
	try {
		const {
			asset,
			walletAddress,
			targetAddr,
			amountToken,
			fromNetwork,
			toNetwork,
		} = payload;
		// const network = await getNetworkStellar();
		const network = fromNetwork.key;
		const lockRequest = {
			asset: asset.name,
			asset_issuer: asset.address,
			sender: walletAddress,
			recipient: targetAddr,
			amount: amountToken,
			fee: "1000000",
			chain_name: toNetwork,
		};
		const [resXdr, getXdrError] = await getXdrStellar(lockRequest);
		if (getXdrError) throw getXdrError; // if some error occur with api
		if (resXdr.success) {
			const { xdr } = resXdr;
			const [userSignTx, signError] = await userSignTransactionStellar(
				xdr,
				network
			);
			if (signError) throw signError; // if user fail sign tx
			const submitted = await stellar_api.submitTransactionStellar(
				userSignTx
			);
			console.log(submitted);
			return submitted;
		}
		throw new Error(resXdr.message); // if cant get XDR for tx
	} catch (err) {
		console.log(err);
		throw err; // if tx sending error
	}
};

export const sendContractEthereum = async (payload) => {
	const {
		web3Instance,
		walletAddress,
		bridgeBank_ABI,
		bridgeBankAddress,
		tokenAddress,
		tokenConvertStr,
		tokenDecimal,
		toNetwork,
		targetAddr,
		amountToken,
		checkNativeCurr,
		wallet,
		asset,
		fromNetwork,
	} = payload;
	if (!web3Instance) return;
	try {
		if (toNetwork === "stellar") {
			const txHash = await sendContractToStellar({
				asset,
				walletAddress,
				targetAddr,
				amountToken,
				fromNetwork,
				toNetwork,
				bridgeBankAddress,
				wallet,
			});
			return txHash;
		}
		const contr = await new web3Instance.eth.Contract(
			bridgeBank_ABI.default ? bridgeBank_ABI.default : bridgeBank_ABI,
			bridgeBankAddress
		);
		const lock = await new Promise((resolve, reject) =>
			contr.methods
				.lock(
					targetAddr,
					tokenAddress,
					tokenConvertStr
						? web3Instance.utils.toWei(
							String(amountToken),
							tokenConvertStr
						)
						: toWeiStr(amountToken, tokenDecimal),
					toNetwork
				)
				.send(
					{
						from: walletAddress,
						gas: 300000,
						value: checkNativeCurr
							? web3Instance.utils.toWei(
								String(amountToken),
								"ether"
							)
							: 0,
					},
					(error, res) => {
						if (wallet?.injectedObject === "BinanceChain") {
							console.log(error, res);
							if (error) reject(error);
							resolve(res);
						}
					}
				)
				.on("confirmation", (confNumber, receipt, latestBlockHash) =>
					resolve(receipt)
				)
				.on("error", (error) => reject(error))
		);

		return lock;
	} catch (err) {
		throw err;
	}
};

export const addTokenToMetaMask = async ({ asset, wallet, chain }) => {
	try {
		let decimals = 0;
		if (asset.decimals?.from) decimals = asset.decimals.from;
		else {
			const [res, error] = await getTokenDecimalEthereum({
				wallet,
				chain,
				token: asset,
			});
			if (error) return [null, error];
			const { tokenDecimal } = res;
			decimals = tokenDecimal;
		}
		const params = {
			type: "ERC20",
			options: {
				address: asset.address,
				symbol: asset.name,
				decimals: decimals,
			},
		};
		if (asset.img.includes("http://") || asset.img.includes("https://"))
			params.options.image = asset.img;
		await window.ethereum.request({
			method: "wallet_watchAsset",
			params,
		});
		return [1, null];
	} catch (error) {
		return [null, error];
	}
};

export const getAddressLink = (address, network) => {
	const scanLink = MULTICHAIN_SUPPORT_SCAN_LINK[network];
	switch (network) {
		case "stellar":
			return scanLink + "/account/" + address;
		default: {
			return scanLink + "/address/" + address;
		}
	}
};

export const roundNumber = (num, scale) => {
	if (!("" + num).includes("e")) {
		return +(Math.round(num + "e+" + scale) + "e-" + scale);
	} else {
		var arr = ("" + num).split("e");
		var sig = "";
		if (+arr[1] + scale > 0) {
			sig = "+";
		}
		return +(
			Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) +
			"e-" +
			scale
		);
	}
};

export const getMultiTokenDecimalEthereum = async ({
	wallet,
	chain,
	listToken,
}) => {
	const tokenABI = BRIDGE_TOKEN_ABI[chain];
	const networkMatch = MULTICHAIN_SUPPORT_ID[chain];

	try {
		const web3Instance = wallet
			? getWeb3WalletInstance(wallet)
			: web3HttpInstance[chain];
		let failList = [];
		await Promise.all(
			listToken.map(
				(pair) =>
					new Promise((resolve, reject) => {
						const tokenContract = new web3Instance.eth.Contract(
							tokenABI,
							pair.from_address
						);
						tokenContract.methods
							.decimals()
							.call()
							.then((res) => {
								const payload = {
									address: pair.from_address,
									chain_id: networkMatch.decimal,
									chain: chain,
									symbol: pair.from_symbol,
									decimals: res,
								};
								storeToCache(
									ConstantContract.DECIMALS_CACHES,
									`${pair.from_symbol}/${chain}`,
									payload
								);
								resolve(true);
							})
							.catch((error) => {
								if (
									error?.message.includes(
										"Invalid JSON RPC response"
									)
								) {
									resolve(true);
									return;
								}
								failList.push(pair);
							});
					})
			)
		);
		if (failList.length) return [null, failList, null];
		return [1, null, null];
	} catch (error) {
		return [null, null, error];
	}
};

export const getTokenDecimalEthereum = async ({
	web3Instance,
	wallet,
	chain,
	token,
}) => {
	const tokenABI = BRIDGE_TOKEN_ABI[chain];
	const networkMatch = MULTICHAIN_SUPPORT_ID[chain];

	try {
		const web3 = web3Instance
			? web3Instance
			: wallet
				? getWeb3WalletInstance(wallet)
				: web3HttpInstance[chain];
		if (isNativeToken(token.address))
			return [{ tokenDecimal: "18", convertString: "ether" }, null];
		const tokenContract = await new web3.eth.Contract(
			tokenABI,
			token.address
		);
		const tokenDecimal = await tokenContract.methods.decimals().call();
		const matchDecimal = ConstantContract.DECIMALS.find(
			(item) => item.key === tokenDecimal
		);
		// store to cache
		const payload = {
			address: token.address,
			chain_id: networkMatch.decimal,
			chain: chain,
			symbol: token.symbol,
			decimals: tokenDecimal,
		};
		await storeToCache(
			ConstantContract.DECIMALS_CACHES,
			`${token.symbol}/${chain}`,
			payload
		);
		return [
			{
				tokenDecimal: parseInt(tokenDecimal),
				convertString: matchDecimal?.value || matchDecimal,
			},
			null,
		];
	} catch (error) {
		return [null, error];
	}
};

export const getTokenImageAndName = async () => {
	try {
		const resultImage = await featureApi.getCoinImage();
		const listImage = chain(resultImage)
			.keyBy("symbol")
			.mapValues("url")
			.value();
		const listDetailName = chain(resultImage)
			.keyBy("symbol")
			.mapValues("name")
			.value();
		return { listImage, listDetailName };
	} catch (error) {
		throw error;
	}
};

export const toWeiStr = (amount, decimal) => {
	decimal = parseInt(decimal);
	let decimalNumber = 0;
	if (amount.toString()?.indexOf(".") != -1) {
		decimalNumber =
			amount.toString().length - (amount.toString()?.indexOf(".") + 1);
	}
	let res = amount.toString().split(".").join("");
	if (decimal < decimalNumber) {
		let lastDecimal = res.charAt(
			res.length - (decimalNumber - decimal) - 1
		);
		res =
			res.substring(0, res.length - (decimalNumber - decimal) - 1) +
			(parseInt(lastDecimal) + 1).toString();
	} else {
		res = res + "0".repeat(decimal - decimalNumber);
	}
	return res;
};

export const toWeiNumber = (amount, decimal) => {
	decimal = parseInt(decimal);
	return new BigNumber(amount * Math.pow(10, decimal)).toNumber();
};

export const formWei = (amount, decimal) => {
	decimal = parseInt(decimal);
	if (decimal != 0) {
		const amountToBN = new BigNumber(amount);
		let decimalPow = "1" + "0".repeat(decimal);

		const decimalToBN = new BigNumber(decimalPow);
		const newAmount = amountToBN.dividedBy(decimalToBN);
		return newAmount.toString();
	}

	if (amount.toString()?.indexOf(".") != -1)
		return (amount - (amount % 1) + 1).toString();
	return amount.toString();
};

export const zeroCutter = (value) => {
	if (typeof value !== "string") return value;
	if (!value.indexOf(".")) return value;
	const leng = value.length;
	let zeroPos = 0;
	for (let i = leng - 1; i >= 0; i--) {
		if (value.charAt(i) === ".") {
			zeroPos = i;
			break;
		}
		if (value.charAt(i) !== "0") {
			zeroPos = i + 1;
			break;
		}
	}
	return value.substring(0, zeroPos);
};

export const convertAddress = (address) => {
	if (Web3.utils.isAddress(address)) {
		return Web3.utils.toChecksumAddress(address.toUpperCase());
	} else {
		return address;
	}
};
