import axiosClient from "./axiosClient";

const featureApi = {
	getTokenPair(params) {
		const url = "tokenPair/get";
		return axiosClient.get(url, { params: params });
	},
	getOrderHistory(address, page, params) {
		const url = `orderHistory/${address}/${page - 1}/10`;
		return axiosClient.get(url, { params: params });
	},
	getDailyQuota(params) {
		const url = "dailyQuota/get";
		return axiosClient.get(url, { params: params });
	},
	getTokenPrice(params) {
		const url = "tokenPrice/get";
		return axiosClient.get(url, { params: params });
	},
	getRefundHistory(address, page, params) {
		const url = `refundHistory/${address}/${page - 1}/10`;
		return axiosClient.get(url, { params: params });
	},
	getCoinImage(params) {
		const url = "coinImage/get";
		return axiosClient.get(url, { params: params });
	},
	getQuota(params) {
		const url = "quota/get";
		return axiosClient.get(url, { params: params });
	},
	checkDailySwap(params) {
		const url = "checkTxWillExc";
		return axiosClient.get(url, { params });
	},
	getProtocolFee(data) {
		const url = "transactionFee/get";
		return axiosClient.post(url, { ...data });
	},
	sendLockStellar(data) {
		const url = "stellar-transaction/lock";
		return axiosClient.post(url, { ...data });
	},
	sendLockEvmStellar(data) {
		const url = "evm-stellar-transaction/lock";
		return axiosClient.post(url, { ...data });
	},
	checkStellarTrustLine(data) {
		const url = "trust-line/get";
		return axiosClient.post(url, { ...data });
	},
};

export default featureApi;
