import React, { useCallback, useMemo, useState } from "react";
import copy from "copy-to-clipboard";
import {
	getAddressLink,
	ToAbsoluteUrl,
	truncateAddressMid,
	isNativeToken,
} from "ultis";
import { useWindowResizeMobile } from "hook/ultis.hook";
import "./index.scss";

export default function AddressShortcut({
	address = "",
	chain,
	isMobile,
	stellar_asset,
}) {
	const [imgFading, setImgFading] = useState(false);
	const isXsSize = useWindowResizeMobile(400);
	const addressShortcut = useMemo(() => {
		const lengthString = !isMobile ? 10 : isXsSize ? 4 : 7;
		return `${truncateAddressMid(address, lengthString, lengthString)}`;
	}, [address, isMobile, isXsSize]);

	const tokenType = useMemo(() => {
		switch (chain) {
			case "stellar":
				if (stellar_asset)
					return stellar_asset.asset_type === "credit_alphanum4"
						? "cra4"
						: "cra12";
				return "Unknown";
			default:
				return chain === "eth" || chain === "evrynet"
					? "ERC20"
					: "BEP20";
		}
	}, [chain, stellar_asset]);

	const nativeToken = useMemo(() => {
		switch (chain) {
			case "stellar": {
				return (
					!stellar_asset?.asset_issuer &&
					stellar_asset?.asset_type === "native"
				);
			}
			default:
				return isNativeToken(address);
		}
	}, [stellar_asset, address, chain]);

	const copyAddress = useCallback(() => {
		copy(address);
		setImgFading(true);
		setTimeout(() => setImgFading(false), 1000);
		// message.success('Copy successfull!');
	}, [address]);

	const openScanLink = useCallback(() => {
		window.open(getAddressLink(address, chain));
	}, [address]);

	return (
		<div className="address-wrapper">
			<span className="address-text">{addressShortcut}</span>
			{nativeToken ? (
				<span className="native-badge">Native</span>
			) : (
				<>
					<span className="erc-type">{tokenType}</span>
					<span className="network-type">{chain}</span>
				</>
			)}
			<div
				className={`box-icon copy ${imgFading ? "success" : ""}`}
				onClick={copyAddress}
			>
				<img
					className={`icon copy-icon`}
					src={ToAbsoluteUrl("/images/icon/icon-gray-copy.svg")}
					alt=""
				/>
				<img
					className={`icon copy-icon--success`}
					src={ToAbsoluteUrl("/images/icon/icon-gray-success.svg")}
					alt=""
				/>
			</div>
			<div className="box-icon link" onClick={openScanLink}>
				<img
					className="icon link-icon"
					src={ToAbsoluteUrl("/images/icon/icon-gray-link.svg")}
					alt=""
				/>
			</div>
		</div>
	);
}
