import { CONTRACT_ADDRESS, Bridge_ETH } from "constants/index";
import { useMemo } from "react";

export default function useBridgeContract(network, web3Instance) {
	return useMemo(() => {
		let bridgeAddress = "";
		switch (network) {
			case "bsc":
				bridgeAddress = CONTRACT_ADDRESS.bridgeBSC;
				break;
			case "evrynet":
				bridgeAddress = CONTRACT_ADDRESS.bridgeEVRYNET;
				break;
			default:
				bridgeAddress = CONTRACT_ADDRESS.bridgeETH;
		}
		try {
			return {
				bridgeContract: new web3Instance.eth.Contract(
					Bridge_ETH,
					bridgeAddress
				),
			};
		} catch (error) {
			return { bridgeContract: null };
		}
	}, [network]);
}
