import React from "react";
import AuthGuard from "./auth.guard";

function withAuth(Comp) {
	return () => (
		<AuthGuard>
			<Comp />
		</AuthGuard>
	);
}

export default withAuth;
