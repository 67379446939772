import React, { useEffect, useState } from "react";
import { Empty, Table } from "antd";
import { MULTICHAIN_SUPPORT_SCAN_LINK } from "constants/multichain";
import { truncateAddressMid } from "ultis";
import { useUIContext } from "pages/ui-context";
import AmountLabel from "pages/history/components/AmountLabel";
import SkeletonTable from "../SkeletonTable";

const listStatus = [
	{
		null: "Processing",
		1: "Processing",
		2: "Processing",
		3: "Processing",
		10: "Refunded",
		11: "Rejected",
		12: "Refunding",
		13: "Error",
		20: "Out Of Gas",
	},
	{
		null: "warning",
		1: "warning",
		2: "warning",
		3: "warning",
		10: "success",
		11: "notfound",
		12: "warning",
		13: "error",
		20: "error",
	},
];

const RefundTable = ({ data, onPagination, className, loading }) => {
	const [listDataSantizing, setListDataSantizing] = useState([]);
	const { listTokenPairs } = useUIContext();

	const renderTitle = (title) => <span>{title}</span>;

	const columns = [
		{
			title: renderTitle("Status"),
			dataIndex: "status",
			key: "status",
			width: "10%",
			render: (status) => (
				<div>
					<span className="title">Status</span>
					<div className="status">
						<div
							className={`around ${
								listStatus[1][status] || listStatus[1][null]
							}`}
						></div>
						<span>
							{listStatus[0][status] || listStatus[0][null]}
						</span>
					</div>
				</div>
			),
		},
		{
			title: renderTitle("Amount /Form (Network)"),
			dataIndex: "from",
			key: "from",
			width: "20%",
			render: (from) => (
				<div>
					<span className="title">Refund Address(Network)</span>
					<span className="span1">
						{from.refundAddress
							? truncateAddressMid(from.refundAddress, 8, 8)
							: null}
					</span>
				</div>
			),
		},
		{
			title: renderTitle("Amount"),
			dataIndex: "amount",
			key: "amount",
			width: "20%",
			render: (amount) => (
				<div>
					<span className="title">Amount</span>
					<AmountLabel
						className="mr-2xsm"
						data={{
							amount: amount.value,
							tokenAddress: amount.tokenAddress,
							network: amount.network,
							symbol: amount.symbol,
						}}
					/>
					<span className="span1">{amount.symbol}</span>
				</div>
			),
		},
		{
			title: renderTitle("Deposit Txn Hash"),
			dataIndex: "depositHash",
			key: "depositHash",
			width: "20%",
			render: (depositHash) => (
				<div>
					<span className="title">Deposit Txn Hash</span>
					<span className="span1">
						{depositHash
							? truncateAddressMid(depositHash, 8, 8)
							: null}
					</span>
				</div>
			),
		},
		{
			title: renderTitle("Refund Txn Hash"),
			dataIndex: "refundHash",
			key: "refundHash",
			width: "20%",
			render: (refundHash) => (
				<div>
					<span className="title">Refund Txn Hash</span>
					<a
						href={`${refundHash.scanLink}/tx/${refundHash.txHash}`}
						target="_blank"
						rel="noreferrer"
						className="span1"
					>
						{refundHash.txHash
							? truncateAddressMid(refundHash.txHash, 8, 8)
							: "0"}
					</a>
				</div>
			),
		},
	];

	const dataSantizing = async () => {
		const res = data.docs.map((item, index) => {
			const scanLink = MULTICHAIN_SUPPORT_SCAN_LINK[item.blockchainName];
			const txHash = item.interChainTransactionHash
				? item.interChainTransactionHash
				: item.transactionHash;
			return {
				key: index + "_" + item._id,
				status: item.status,
				from: {
					refundAddress: item.from,
					network: item.blockchainName,
				},
				amount: {
					value: item.refunded_value,
					symbol: item.symbol,
					network: item.blockchainName,
					tokenAddress: item.token,
				},
				depositHash: item.transactionHash,
				refundHash: {
					txHash,
					scanLink,
				},
			};
		});
		setListDataSantizing(res);
	};

	useEffect(() => {
		if (listTokenPairs.length && data?.docs?.length) {
			dataSantizing();
		}
	}, [listTokenPairs, data]);

	return loading ? (
		<SkeletonTable columns={columns} rowCount={5} />
	) : (
		<Table
			className={className}
			columns={columns}
			dataSource={listDataSantizing}
			locale={
				<Empty
					description="You have no history."
					image={Empty.PRESENTED_IMAGE_SIMPLE}
				/>
			}
			pagination={{
				disabled: !!!data.docs?.length,
				current: data.page,
				defaultCurrent: data.page,
				currency: data.page,
				total: data.totalDocs,
				pageSize: data.limit,
				hideOnSinglePage: true,
				onChange: onPagination,
				showSizeChanger: false,
				responsive: true,
			}}
		/>
	);
};

export default RefundTable;
